import { useContextSelector } from 'use-context-selector';
import { TagManagerContext } from '../../context/TagManager/TagManagerContext';


export const useEncryption = () => {
    const encryptedId = useContextSelector(TagManagerContext, (context) => context.encryptedId);
    const encryptedEmail = useContextSelector(TagManagerContext, (context) => context.encryptedEmail);
    const encryptUserData = useContextSelector(TagManagerContext, (context) => context.encryptUserData);

    return { encryptedId, encryptedEmail, encryptUserData };
};


export const useVirtualPageView = () => {
    const onVirtualPageView = useContextSelector(TagManagerContext, (context) => context.onVirtualPageView);

    return { onVirtualPageView };
};


export const useInitiateOrderTracking = () => {
    const onInitiateOrder = useContextSelector(TagManagerContext, (context) => context.onInitiateOrder);
    
    return { onInitiateOrder };
};


export const useInputTracking = () => {
    const onInputChange = useContextSelector(TagManagerContext, (context) => context.onInputChange);

    return { onInputChange };
};


export const usePurchaseTracking = () => {
    const onPurchase = useContextSelector(TagManagerContext, (context) => context.onPurchase);

    return { onPurchase };
};

export const useShippingTracking = () => {
    const onShipping = useContextSelector(TagManagerContext, (context) => context.onShipping);

    return { onShipping };
}
