import React, { useEffect } from 'react';

import LoadingService from '../services/LoadingService';


const FallbackComponent = () => {
    useEffect(() => {
        LoadingService.show(`Carregando...`);

        //return LoadingService.hide();
    }, []);
    
    return null;
}

export default FallbackComponent;