export class RecorrenciaResponse {
    status: boolean
    data: object
    error: string;
    
    constructor(init?:Partial<RecorrenciaResponse>){
        Object.assign(this, init);
    }

}

export default RecorrenciaResponse;