class TelefoneService {
    static formatar(tel: string = '') {

        if (!tel) return tel;
        //retira os caracteres indesejados...
        tel = tel.replace(/[^\d]/g, "");

        //realizar a formatação...
        let out: string = '';

        if (tel.length === 10) {
            out = tel.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
        } else
            if (tel.length === 11) {
                out = tel.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
            } else {
                console.warn('Telefone invalido para formatação.')
            }

        return out;
    }
}

export default TelefoneService;