import React from 'react';
import ReactDOM from 'react-dom';

import { IoIosClose } from 'react-icons/io';
import { AlertBox } from './styles';


const AlertComponent = ({ visible, closeAlert }) => {
    
    const handleClose = () => { 
        closeAlert();
    };

    return visible && ReactDOM.createPortal(
            <AlertBox>
                <span>
                    <b>Sucesso!</b> Produto adicionado com sucesso.
                </span>
                <IoIosClose onClick={handleClose} />
            </AlertBox>, 
        document.getElementById("portal")
    );      
};

export default AlertComponent;