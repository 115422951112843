export const initialFrequencyState = [
    { label: "Não se repete", value: null, chosen: true },
    { label: "Semanal", value: 7, chosen: false },
    { label: "Quinzenal", value: 14, chosen: false },
    { label: "Mensal", value: 30, chosen: false }
]


export const reducerFrequency = (state, action) => {
    switch (action.type) {
        case 'CHANGE_FREQUENCY':
            const new_state = state.map(frequency => {
                return {
                    label: frequency.label,
                    value: frequency.value,
                    chosen: frequency.value === action.payload
                }
            });

            return new_state;
        default:
            return state;
    }
};