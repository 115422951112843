
import TermoLGPD from "../models/TermoLGPD";
import apiPedido from "./apis/ApiPedido";



class LGPDService {
    /**
     * Checa se um cpf aceitou os termos
     * @param cpf 
     * @returns 
     */
    static async checkAceite(cpf: string): Promise<TermoLGPD> {

        const uri = 'LGPD/ListaAceite/' + cpf.replace(/[^0-9]/g, '');

        const res = await apiPedido.get(uri);

        let termo = new TermoLGPD(res.data.valor);

        return termo;
    }

    /**
     * Salva o termo para um cpf e retorna um bool se salvou com sucesso
     * @param termo 
     * @returns 
     */
    static async salvarTermo(termo: TermoLGPD): Promise<boolean> {

        const uri = 'LGPD/SalvarTermo/';

        const res = await apiPedido.post(uri, JSON.stringify(termo));

        return res.data.valor;
    }

    /**
     * Verifica se precisa salvar o termo para um cpf e salva se necessário
     * @param cpf 
     * @returns 
     */
    static async salvarTermoSeNecessario(cpf: string): Promise<boolean> {
        const termo = await LGPDService.checkAceite(cpf);

        if (!termo.Id) {
            termo.Cpf = cpf;

            const salvouTermo = await LGPDService.salvarTermo(termo);

            return salvouTermo;
        } else {
            return Promise.resolve(true);
        }
    }
}

export default LGPDService;