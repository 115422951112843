import { RequestChecarSubstituicaoLojaInterface } from "../interfaces/RequestChecarSubstituicaoLojaInterface";
import Cupom from "../models/Cupom";
import DiaRecorrencia from "../models/DiaRecorrencia";
import Horario from "../models/Horario";
import MeioDeContato from "../models/MeioDeContato";
import RecorrenciaRequest from "../models/RecorrenciaRequest";
import RecorrenciaResponse from "../models/RecorrenciaResponse";
import SubstituicaoLoja from "../models/SubstituicaoLoja";
import apiPedido from "./apis/ApiPedido";
import apiExterna from "./apis/ApiExterna";

class RecorrenciaService {
  static async checkIsHoliday(date: any): Promise<MeioDeContato[]> {
    const params = {
      CodLoja: 0,
      Data: date,
      Recorrencia: true,
    };
    const res = await apiPedido.post(
      "/Recorrencia/DataInformadaEDomingoOuFeriado",
      params
    );

    return res.data.valor;
  }

  static async getAllContacts() {
    const request = await apiPedido.get("/Recorrencia/ListaMeioDeContato");

    return request.data.valor
      .filter((item: any) =>
        ["Conversa por WhatsApp", "Ligacao"].includes(item.DescMeioDeContato)
      )
      .map((item: any) => ({
        id: item.CodMeioDeContato,
        value: item.DescMeioDeContato,
      }));
  }
  static async salvar(data: RecorrenciaRequest): Promise<RecorrenciaResponse> {
    const res = await apiPedido.post(
      "/Pedidos/ConfirmarRecorrenciaTelaPrincipal",
      data
    );

    const recorrenciaResponse = new RecorrenciaResponse({
      status: res.data.resultado,
      data: res.data.valor,
      error: res.data.resultado ? null : res.data.valor,
    });

    return recorrenciaResponse;
  }

  static async listaMeiosDeContato(): Promise<MeioDeContato[]> {
    const res = await apiPedido.get("/Recorrencia/ListaMeioDeContato");

    const lista = res.data.valor.map(
      (item: MeioDeContato) => new MeioDeContato(item)
    );

    return lista;
  }

  static async listaDiaRecorrencia(): Promise<DiaRecorrencia[]> {
    const res = await apiPedido.get("/Recorrencia/ListarDiaRecorrenciaModel");

    const lista = res.data.valor.map(
      (item: DiaRecorrencia) => new DiaRecorrencia(item)
    );

    return lista;
  }

  static async getCupom(cupom: string) {
    try {
      const res = await apiExterna.get(`Promocao/GetByCupom?cupom=${cupom}`);

      const dadosCupom = new Cupom({
        Resultado: res.data.resultado,
        Valor: res.data.valor ? res.data.valor : null,
      });

      return { error: !res.data.resultado, data: dadosCupom };
    } catch (e) {
      return { error: true, data: e };
    }
  }

  static async getHasRecorrencia(
    codLoja: string,
    parametro: Number
  ): Promise<any> {
    try {
      const res = await apiPedido.get(
        `/RecorrenciaWZap/CarregarValorPorParametro/${codLoja}/${parametro}`
      );
      const hasRecorrencia = res.data.valor?.Valor === "1";
      const error = !!res.data.errorMessage;

      return {
        error,
        data: { hasRecorrencia },
      };
    } catch (e) {
      return { error: true, data: e };
    }
  }

  static async getRecorrenciaInfo(
    codRecorrencia: any
  ): Promise<any> {
    try {
      const res = await apiPedido.get(
        `/Pedidos/RecorrenciasConfirmadasNesteMes/${codRecorrencia}`
      );
      const error = !!res.data.errorMessage;

      return {
        error,
        data: res.data.valor,
      };
    } catch (e) {
      return { error: true, data: e };
    }
  }

  static async carregarOfertasPersonalizadas(cpf: string, id: string): Promise<any> {
    try {
      const res = await apiPedido.get(
        `/RecorrenciaWZap/OfertaPersonalizada/${cpf}/${id}`
      );

      if (res.data.resultado) {
        return res.data.valor;
      }
    } catch (e) {
      return { error: true, data: e }
    }
  }

  static async listaHorario(): Promise<Horario[]> {
    const res = await apiPedido.get("/Recorrencia/ListarHorarios");

    const lista = res.data.valor.map((item: Horario) => new Horario(item));
    
    return lista;
  }

  static async listaHorarioDisponivelEntregaPorData(
    codLoja: string,
    data: Date,
    isRecorrencia = false
  ): Promise<Horario[]> {
    const params: {
      CodLoja: string;
      Data: Date;
      Recorrencia?: boolean;
    } = {
      CodLoja: codLoja,
      Data: data,
    };

    if (isRecorrencia) {
      params["Recorrencia"] = true;
    }

    const res = await apiPedido.post(
      "/Recorrencia/ListarHorariosDisponivelParaPedidoPOdData",
      params,
      {
        "axios-retry": {
          retryCondition: () => true, // axios-retry não tenta de novo por padrão se for post
        },
      }
    );

    let horarios: Horario[] = [];

    res.data.valor.forEach((item: Horario) => {
      horarios.push(new Horario(item));
    });

    return horarios;
  }

  static async listaHorarioDisponivelRetiradaPorData(
    codLoja: string,
    data: Date
  ): Promise<Horario[]> {
    const res = await apiPedido.post(
      "/Recorrencia/ListarHorariosPedidoPOdData",
      {
        CodLoja: codLoja,
        Data: data,
      },
      {
        "axios-retry": {
          retryCondition: () => true, //axios-retry não tenta de novo por padrão se for post
        },
      }
    );

    let horarios: Horario[] = [];

    res.data.valor.forEach((item: number) => {
      horarios.push(
        new Horario({
          CodHorario: item,
          IndAtivo: true,
          DescHorario: item + "h",
          HorarioIncio: item,
        })
      );
    });

    return horarios;
  }

  static async listaHorarioDisponivelEntregaExpressaPorData(
    codLoja: string,
    data: Date
  ): Promise<Horario[]> {
    const res = await apiPedido.post(
      "/Recorrencia/ListarHorariosPedidoPOdData",
      {
        CodLoja: codLoja,
        Data: data,
      },
      {
        "axios-retry": {
          retryCondition: () => true, //axios-retry não tenta de novo por padrão se for post
        },
      }
    );

    let horarios: Horario[] = [];

    //console.log('RetiradaArmario SLOTS', res.data.valor);

    res.data.valor.forEach((item: number) => {
      if (item === 9 || item === 12 || item === 15 || item === 18) {
        horarios.push(
          new Horario({
            CodHorario: item,
            IndAtivo: true,
            DescHorario: `${item}h - ${item + 3}h`, // retirar em armário: slots de 3 horas.
            HorarioIncio: item,
          })
        );
      }
    });

    return horarios;
  }

  static async checaSubstituicaoLoja(
    codLoja: string,
    data: Date
  ): Promise<SubstituicaoLoja> {
    const busca: RequestChecarSubstituicaoLojaInterface = {
      CodLoja: codLoja,
      Data: data,
    };

    const res = await apiPedido.post("Pedidos/SubstituicaoLoja", busca);

    return new SubstituicaoLoja({
      ...res.data.valor,
      lojaFechada: res.data.valor && res.data.valor.cod_siac ? true : false,
    });
  }

  static async listaHorarioDisponivelRetiradaArmarioPorData(
    codLoja: string,
    data: Date
  ): Promise<Horario[]> {
    const res = await apiPedido.post(
      "/Recorrencia/ListarHorariosPedidoPOdData",
      {
        CodLoja: codLoja,
        Data: data,
      },
      {
        "axios-retry": {
          retryCondition: () => true, //axios-retry não tenta de novo por padrão se for post
        },
      }
    );

    let horarios: Horario[] = [];

    res.data.valor.forEach((item: number) => {
      if (item === 9 || item === 12 || item === 15 || item === 18) {
        horarios.push(
          new Horario({
            CodHorario: item,
            IndAtivo: true,
            DescHorario: `${item}h - ${item + 3}h`, // retirar em armário: slots de 3 horas.
            HorarioIncio: item,
          })
        );
      }
    });

    return horarios;
  }
}

export default RecorrenciaService;
