import styled from 'styled-components';


export const Backdrop = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.60);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    z-index: 10;
`

export const Modal = styled.div`
    max-width: 520px;
    width: 100%;
    //height: 354px;
    height: auto;
    padding: 16px;
    border-radius: 8px;
    background-color: #FFF;
    color: #614B36;
    position: relative;
    > div:first-child {
        height: 25px;
        position: absolute;
        right: 16px;
        top: 8px;
        
        cursor: pointer;
        z-index: 15;
    };
    > div {
        > span {
            font-weight: 700;
        }
        * {
            display: block;
            margin-bottom: 16px;
        }
        textarea {
            height: 76px;
            width: 100%;
            background-color: #EEEEEE;
            border: none;
            outline: none;
            padding: 8px;
            resize: none;
            border: 0.75px solid #DADADA;
        }
        button {
            cursor: pointer;
            width: 100%;
            height: 54px;
            border: none;
            outline: none;
            background-color: #4FBE95;
            color: #FFF;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 700;
        }
    }
    header {
        height: 60px;
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        position: relative;
    }
    header > div {
        height: 100%;
        
        display: flex;
        align-items: center;
        div:first-child {
            width: 60px;
            height: inherit;
            margin-right: 8px;
            img {
                max-width: 100%;
                max-height: 100%; 
            };
        };
        div:last-child {
            align-self: flex-start;
            span:first-child {
                max-width: 40ch;
                //white-space: normal;
                
                @media(max-width:599px) {
                    max-width: 25ch;
                };
            }
            span {
                display: block;
            }
            span h1 {
                font-size: 16px;
                font-weight: 700;
            }
        }
    }
    footer {
        font-size: 14px;
        display: flex;
        justify-content: center;
        div span {
            text-align: justify;
        }
        @media(max-width:599px) {
            font-size: 12px;
        };
    }
`;