import React, { useEffect } from 'react';
import { Input } from "reactstrap";
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useInputTracking } from '../../hooks/TagManager/index';
import VitrineCarousel from "components/Pedido/VitrineCarousel";

import * as Styled from "./styles";


interface TextAreaFieldProps {
  name: string;
  register: any; // do not need to pass it here
  placeholder?: string | undefined;
  id?: string;
  label?: string;
  image?: string;
  noMb?: boolean;
  readOnly?: boolean;
  limit?: number;
  isOfertas?: boolean | undefined;
};


const TextAreaField = (props: TextAreaFieldProps) => {
  const { onInputChange } = useInputTracking();
  const { location } = useHistory();

  const { watch, setValue } = useFormContext();
  const watchFieldValue = watch(props.name);

  // useEffect(() => {
  //   const hasStoragedFieldValue = localStorage.getItem(props.name) || null;
  //   if (hasStoragedFieldValue) {
  //     setTimeout(() => {
  //       setValue(props.name, hasStoragedFieldValue);
  //     }, 1500);
  //   }
  // }, [props.name, setValue]); 

  

  const onFieldBlur = () => {
    onInputChange({
      name: props.name,
      type: location.pathname === '/pedido' ? 'pedido-avulso' : 'pedido-recorrente'
    });


  };


  return (
    <Styled.Wrapper>
      {props.label && <Styled.SectionTitleList id={props.id}>{props.label}</Styled.SectionTitleList>}
      {
        !props.isOfertas && (
          <VitrineCarousel images={props.image} />
        )
      }
      <Input
        type="textarea"
        style={{ marginBottom: props.noMb ? 0 : 32 }}
        name={props.name}
        innerRef={props.register}
        onBlur={onFieldBlur}
        placeholder={props.placeholder}
        rows="10"
        readOnly={props.readOnly || false}
        maxLength={props.limit}
      />
    </Styled.Wrapper>
  )
};

export default TextAreaField;