import React, { useState, useReducer, useEffect, useMemo } from 'react';

import { useFieldArray } from 'react-hook-form';

import { reducerFrequency, initialFrequencyState } from './frequencyReducer';

import { TiArrowLoop } from 'react-icons/ti';
import { IoIosArrowDown } from 'react-icons/io';

import { Container, Options } from './styles';


const Select = ({ handleMenuActions: { isActive, setIsActive } }) => {
    const { append } = useFieldArray({ name: 'frequency' });

    const [frequencies, dispatch] = useReducer(reducerFrequency, initialFrequencyState);

    useEffect(() => {
        const chosenFrequency = frequencies.filter(f => f.chosen);

        if (chosenFrequency[0].value) {
            append({ frequency: true, diaRecorrencia: chosenFrequency[0].value });
        } else {
            append({ frequency: false, diaRecorrencia: null });
        }
    }, [frequencies])

    const handleChangeValue = (value) => {
        dispatch({
            type: 'CHANGE_FREQUENCY',
            payload: value
        });

        setIsActive(false);
    };

    const handleClick = (e) => {
        setIsActive(!isActive);
        e.stopPropagation();
    }

    const chosenFrequency = useMemo(() => {
        return frequencies.filter(f => f.chosen);
    }, [frequencies])

    const getChosenLabel = () => {
        //let chosenFrequency = frequencies.filter(f => f.chosen);
        return chosenFrequency[0].label;
    }

    const userWantsFrequency = () => {
        //let chosenFrequency = frequencies?.filter(f => f.chosen);

        return !!chosenFrequency[0].value;
    };


    return (
        <>
            <Container onClick={handleClick} chosen={userWantsFrequency()} >
                <div>
                    <TiArrowLoop />
                    {getChosenLabel()}
                </div>
                <IoIosArrowDown color='#A35E8E' />
            </Container>
            {
                isActive && (
                    <Options>
                        <ul>
                            {
                                frequencies?.map(f => (
                                    <li onClick={() => handleChangeValue(f.value)} >
                                        {f.label}
                                    </li>
                                ))
                            }
                        </ul>
                    </Options>
                )
            }
        </>
    );
};


export default Select;