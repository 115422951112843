import { combineReducers } from "redux";
import { clienteReducer } from "./clienteReducer";
import { authReducer } from "./authReducer";
import { pedidoReducer } from "./pedidoReducer";
import { recorrenciaReducer } from "./recorrenciaReducer";
import { lojaEscolhidaReducer } from "./lojaEscolhidaReducer";
import { horariosDisponiveisReducer } from "./horariosDisponiveisReducer";
import * as ACTIONS from "../actions/actionTypes";
import { pedidoEnviadoReducer } from "./pedidoEnviadoReducer";
import { pedidoRecorrenciaEnviadoReducer } from "./pedidoRecorrenciaEnviadoReducer";
import { ambienteReducer } from "./ambienteReducer";
import { horariosDisponiveisRecorrenciaReducer } from './horariosDisponiveisRecorrencia';
import contacts from "store/slices/recorrencia/contacts";
import notification from "store/slices/notification";

const appReducer = combineReducers({
  clienteState: clienteReducer,
  authState: authReducer,
  pedidoState: pedidoReducer,
  recorrenciaState: recorrenciaReducer,
  lojaEscolhidaState: lojaEscolhidaReducer,
  horariosDisponiveisState: horariosDisponiveisReducer,
  horariosDisponiveisRecorrenciaState: horariosDisponiveisRecorrenciaReducer,
  pedidoEnviadoState: pedidoEnviadoReducer,
  pedidoRecorrenciaEnviadoState: pedidoRecorrenciaEnviadoReducer,
  ambienteState: ambienteReducer,
  contacts: contacts,
  notification: notification,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === ACTIONS.USER_LOGOUT) {
    state = initialState;
  }

  return appReducer(state, action);
};

export default rootReducer;
