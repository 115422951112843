import React from 'react';
import { useFormContext } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { Input, Label } from 'reactstrap';

const Endereco = (props: any) => {
    const { register, errors } = useFormContext();

    return (
        <div>
            <div className="mb3">
                <p className="textAvulso requerido mb2"><b>Cep</b></p>
                <Input name="Cep" innerRef={register({ required: true })} invalid={errors.Cep ? true : false} mask="99999-999" maskPlaceholder={null} tag={ReactInputMask} readOnly></Input>
            </div>
            <div className="mb3">
                <p className="textAvulso requerido mb2"><b>Endereço</b></p>
                <Input
                    name="Endereco"
                    maxLength={100}
                    innerRef={register({ required: true })}
                    invalid={errors.Endereco ? true : false}
                    readOnly></Input>
            </div>
            <div className="mb3">
                <p className="textAvulso requerido mb2"><b>Número</b></p>
                <Input
                    name="Numero"
                    maxLength={10}
                    innerRef={register({ required: true })}
                    invalid={errors.Numero ? true : false}
                ></Input>
            </div>
            <div className="mb4">
                <p className="textAvulso mb2"><b>Complemento</b></p>
                <Input name="Complemento" maxLength={40} innerRef={register} ></Input>
            </div>
        </div>
    );
}

export default Endereco;