import ClienteEndereco from "./ClienteEndereco";

class Cliente {
    Nome: string;
    Cpf: string;
    DataNascimento: string;
    Telefone: string = '';
    Email: string;
    Enderecos: ClienteEndereco[];
    IndRecorrencia: Boolean;
    Preferencias: string;
    GoogleUID?: string[];

    constructor(init?: Partial<Cliente>) {
        Object.assign(this, init);
    }
}

export default Cliente;