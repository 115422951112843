import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Input, Label } from "reactstrap";
import PedidoService from "../../services/PedidoService";

const Cupom = (props: any) => {

  const [resultadoCupom, setResultadoCupom] = useState("");
  const [cupom, setCupom] = useState("");

  const { register } = useFormContext();

  useEffect(() => {
    const validarCupom = async () => {
      if (cupom.length > 4) {
        let validaCupom = await PedidoService.getCupom(cupom);
        if (validaCupom) {
          setResultadoCupom(validaCupom.Valor);
        }
      } else {
        setResultadoCupom("");
      }
    };

    validarCupom();
  }, [cupom]);


  return (
    <div className="row mb6">
      <div className="col-lg-12">
        <p className="textAvulso"><b>Cupom de desconto</b></p>
        <p className="textAvulsoSm mb2">Possui algum cupom? Use aqui!</p>
        <Input
          name="Cupom"
          id="cupom"
          placeholder="Código do Cupom"
          onChange={(e) => setCupom(e.target.value)}
          innerRef={register()}
          spellCheck="false"
          maxLength={20}
          autocomplete="off" 
        />
        <span id="resultadoCupom">{resultadoCupom}</span>
      </div>
    </div>
  );
};

export default Cupom;
