import React from 'react';
import isAutenticado from '../auth';

import { Route, Redirect } from 'react-router-dom';
import Layout from '../components/Layout';
import { store } from '../store';

const getLoginPage = () => {

    const { ambienteState } = store.getState();

    if (ambienteState.data === 'normal') {
        return '/login';
    } else {
        return '/login-' + ambienteState.data;
    }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props =>
        isAutenticado() ? (
            <Layout {...props}>
                <Component {...props} />
            </Layout>
        ) : (
            <Redirect to={{ pathname: getLoginPage(), state: { from: props.location } }} />
        )
    }></Route>
)

export default PrivateRoute;