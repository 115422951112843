import React from 'react';

import ItemOfertaComponent from './ItemOferta';

import { Container } from './styles';


const Carrousel = React.forwardRef(({ offers, pedidoAgendado }, ref) => {
    return (
        <Container ref={ref}>
            {
                !!offers.length && offers.map((o, idx) => (
                    <ItemOfertaComponent
                        key={o.promotion.description}
                        description={o.promotion.description}
                        originalPrice={o.promotion.originalPrice}
                        finalPrice={o.promotion.finalPrice}
                        imgUrl={o.promotion.urlImage1}
                        pedidoAgendado={pedidoAgendado}
                    />
                ))
            }
        </Container>
    )
});

export default Carrousel;