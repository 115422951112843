import * as ACTIONS from '../actions/actionTypes';

const initialState = {
  data: {}
}

export const pedidoRecorrenciaEnviadoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.PEDIDO_RECORRENCIA_ENVIADO:
      return {
        ...state,
        data: action.data
      };
    default:
      return state;
  }
}