export class Horario {
    CodHorario: number;
    DescHorario: string;
    IndAtivo: boolean;
    HorarioIncio: number;

    constructor(init?: Partial<Horario>) {
        Object.assign(this, init);
    }

}

export default Horario;