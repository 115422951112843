import React, { useEffect, useState } from 'react';

import { Container, Loading } from './styles';

import Lottie from 'react-lottie';
import animationData from './walker-fruit-lottie.json';
import loading from './loading2.json';

const phrases = [
    "A idade não é importante. A menos que você seja um queijo. Ou um vinho.",
    "O amendoim é parente do feijão e da soja.",
    "Qual o legume que ganhou na loteria? O milhonário.",
    "O estudo das frutas se chama pomologia.",
    "A fruta preferida do gaúcho é o abacaTchê.",
    "Pepinos também são frutas.",
    "Quem é o dono da horta? Seu Noura.",
    "A laranja não está nem entre os primeiros alimentos com mais vitamina C.",
    "Morangos tem mais vitamina C do que a laranja.",
    "Tomates tem mais genes do que seres humanos.",
    "Vegetais e frutas não morrem quando são colhidos e ainda continuam se desenvolvendo durante dias.",
    'Existe uma árvore chamada "Salada de Frutas", que produz entre 3 e 7 tipos diferentes de frutas.',
    "Para reduzir a acidez do abacaxi, adicione uma pitada de sal.",
    "Os seres humanos dividem metade do seu DNA com as bananas.",
    "Grãos de café são considerados sementes de fruta.",
    "Maçãs, pêssegos e framboesas são membros da família das rosas.",
    "O coco-do-mar é a maior fruta do mundo, chegando a pesar 42kg - suas sementes sozinhas pesam até 17kg.",
    "Qual é o ritmo musical favorito das plantas? O Reggae.",
    "Para onde o mamão vai nas férias? Papaya.",
    "O que o tomate foi fazer no banco? Tirar um extrato.",
    "Qual é a fruta que ajuda os amigos? A uva parça.",
    "O último açaí fecha a porta!",
    "O que uma castanha falou pra outra? É noz!",
    "Por que a banana sempre fica em segundo lugar? Porque é Banana Prata.",
    "Nossos produtos são escolhidos a dedo, assim como as nossas piadas."
]

const AnimationTransition = ({ renderPhrases }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const loadingAnimationOptions = {
        animationData: loading,
    }

    const [phraseIndex, setPhraseIndex] = useState(0);

    useEffect(() => {
        const showPhrases = setInterval(() => {
            setPhraseIndex(phraseIndex => {
                if (phraseIndex === phrases.length - 1) {
                    return 0;
                }

                return phraseIndex + 1;
            });
        }, 3000)

        return () => clearInterval(showPhrases);
    }, []);

    function shuffle(o) {
        for (var j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
        return o;
    }

    return (
        <Container >
            <Loading renderPhrases={renderPhrases} >
                <div>
                    <Lottie options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    />
                </div>
                <div>
                    <div>
                        <Lottie options={Object.assign(defaultOptions, loadingAnimationOptions)} />
                    </div>
                    {
                        renderPhrases ? (
                            <span>
                                {shuffle(phrases)[phraseIndex]}
                            </span>
                        ) : (
                            <span>
                                Enviando pedido...
                            </span>
                        )
                    }
                </div>
            </Loading>
        </Container>
    )
};

export default AnimationTransition;