import * as Styled from "./styles";

interface FixedNavigatorProps {
  items: {
    target: string;
    icon?: any;
    iconWhite?: any;
    separator?: boolean;
    firstBlock?: boolean;
    avulso?: boolean;
    label: string;
  }[];
}
const FixedNavigator = ({ items }: FixedNavigatorProps) => {
  const handleFixedNavigator = (target: string) => (event: any) => {
    event.preventDefault();
    const element = document.querySelector(`#${target}`);

    if (element) element.scrollIntoView({ behavior: "smooth" });
    return false;
  };

  return (
    <Styled.Wrapper>
      <Styled.Title>
        Navegue pela página
      </Styled.Title>
      <nav>
        {items.filter((i) => !!i.firstBlock).map((item, idx) => (
          <>
            <Styled.DefaultButton
              key={item.target}
              href={item.target}
              onClick={handleFixedNavigator(item.target)}
              separator={item.separator}
              avulso={item.avulso}
              lastItem={idx === items.length - 2}
            >
              {item.icon && <>
                <img className="defaultImg" src={item.icon} alt={item.icon} style={{ width: 16 }} />
                <img className="whiteImg" src={item.iconWhite} alt={item.iconWhite} style={{ width: 16 }} />
              </>} {item.label}
            </Styled.DefaultButton>
            {!!item.separator &&
              <Styled.Separator />}
          </>
        ))}
        <Styled.Subitle>
          Lista de compras
        </Styled.Subitle>
        {items.filter((i) => !i.firstBlock).map((item, idx) => (
          <>
            <Styled.DefaultButton
              key={item.target}
              href={item.target}
              onClick={handleFixedNavigator(item.target)}
              separator={item.separator}
              avulso={item.avulso}
              lastItem={idx === items.length - 2}
            >
              {item.icon && <>
                <img className="defaultImg" src={item.icon} alt={item.icon} style={{ width: 16 }} />
                <img className="whiteImg" src={item.iconWhite} alt={item.iconWhite} style={{ width: 16 }} />
              </>} {item.label}
            </Styled.DefaultButton>
            {!!item.separator &&
              <Styled.Separator />}
          </>
        ))}
      </nav>
    </Styled.Wrapper>
  );
};

export default FixedNavigator;
