import React from 'react';

import { useFormContext } from 'react-hook-form';
import { Input, Label } from 'reactstrap';

import './styles.module.scss';


interface RadioComponentProps {
    name: string;
    value: string;
    text: string;
}

const RadioComponent = ({ name, value, text }: RadioComponentProps) => {
    const { register, watch } = useFormContext();

    const watchFieldValue = watch(name);

    const isChecked = () => {
        return watchFieldValue === value;
    };

    const getContainerClassName = () => {
        if (isChecked()) {
            return 'radioBtnSubstituicao radioSelected';
        }

        return 'radioBtnSubstituicao';
    };


    return (
        <div className={getContainerClassName()}>
            <Label>
                <Input
                    type="radio"
                    name={name}
                    innerRef={register({ required: true })}
                    value={value}
                />
                <div className="textAvulso textRadioBtnWithContainer" >
                    <div>
                        {text}
                    </div>
                    {/* <div style={{ marginLeft: '22px', display: 'block' }}>
                        Na falta de um item, a loja escolhe um similar e faz a troca para você
                    </div> */}
                </div>
            </Label>
        </div>
    )



}

export default RadioComponent;