import React from 'react';

import Skeleton from 'react-loading-skeleton';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import '@splidejs/splide/dist/css/splide.min.css'; // requires a loader
import './styles.scss';


const VitrineCarousel = (props: any) => {
    const images: any = props.images;
    // const multiple: any = props.multiple;

    const classnames = props.topBanner ?
        "vitrine-container mx-auto d-block mb-5 top-banner-skeleton"
        :
        "vitrine-container mx-auto d-block mb-5 skeleton-vitrine";

    const mobileObj = {
        //type: 'loop',
        //height: '10rem',
        //autoWidth: true,
        //padding: '5rem',
        //gap: 12,
    }
    const desktopObj = {
        //perPage: ((window.screen.width > 991) && multiple) ? 3 : 1,
        //flickMaxPages: 1,
        //gap: 24,
        //arrows: false,
    }

    return props.images ? (
        <div className="vitrine-container mx-auto d-block mb-5 classnames">
            {Array.isArray(images) && images?.length > 1 ? (
                <Splide options={window.screen.width > 991 ? desktopObj : mobileObj}>
                    {images.map((imgUrl: any) => {
                        return (
                            <SplideSlide className="swiper-slide">
                                <div className='imgSlide' key={imgUrl}>
                                    <img src={imgUrl} alt="" />
                                </div>
                            </SplideSlide>
                        )
                    })}
                </Splide>) : (
                <div key={Array.isArray(images) ? images[0] : images}>
                    <img src={Array.isArray(images) ? images[0] : images} alt="" />
                </div>
            )}
        </div>
    ) : (
        <div className={classnames} >
            <Skeleton width="100%" height="100%" />
        </div>
    )
}


export default VitrineCarousel;