import toString from 'lodash.tostring';
import trim from 'lodash.trim';


export class Recorrencia {
  CodRecorrencia: number;
  Descricao: string;
  numCpfCnpj: string;
  Cep: string;
  Endereco: string;
  Numero: string;
  Complemento: string;
  FLV: string;
  CarneAvePeixe: string;
  OutrosProdutos: string;
  DHP: string;
  Lanche: string;
  DataCadastroApi: Date;
  LojaId: string;
  DataProximaEntrega: Date;
  RecorrenciaDia: number;
  tipoContato: number;
  StatusRecorrencia: boolean;
  CodHorario: number;
  CodMeioDeContato: number;
  ValorDinheiro: string;
  Nome: string;
  Email: string;
  QtdDiasAntMensagem: number; // 3
  Substituicao: string | boolean;
  formaPagamento: string;
  IncluirSacola: string | boolean;
  TipoEntrega: boolean;
  HoraEntrega: string;
  DataCancelamento: Date;
  MotivoCancelamento: string;
  ReferenciaEndereco: string;
  Telefone: string;
  ComoEntregar: string;
  OfertaPersonalizada: string;

  constructor(init?: Partial<Recorrencia>) {
    Object.assign(this, init);
  }

  /**
   * Transforma os dados para usar em um form
   * @returns
   */
  public transformToForm(onlyFields: string[] = []): any[] {
    let out: any = [];

    Object.entries(this).forEach((item: any) => {
      item[1] = trim(toString(item[1]));

      if (onlyFields.length === 0 || onlyFields.indexOf(item[0]) !== -1) {
        out.push(item);
      }
    });

    return out;
  }
}

export default Recorrencia;
