export class SubstituicaoLoja {
    lojaFechada: boolean = false;
    cod_siac: number;
    Data: Date;
    cod_siac_substituta: number;

    constructor(init?:Partial<SubstituicaoLoja>){
        Object.assign(this, init);
    }
}

export default SubstituicaoLoja;