import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';

import { Input, Label } from 'reactstrap';

import { useFetchHoursAvailable } from 'hooks/recorrencia/fetchHoursAvailable';
import LoadingInline from 'components/LoadingInline';


const StoreClosedInformation = () => (
    <div className="text-danger">
        Olá, infelizmente estaremos fechados na data que você escolheu, mas se for
        possível programar sua entrega para outra data ficaremos felizes em te
        atender.
    </div>
);

const StoreAvailableInformation = ({ isToday }) => (
    <div className="text-danger">
        {isToday
            ? "Devido ao horário, só temos data futura para realizar seu pedido."
            : "Infelizmente não temos nenhum horário para entrega no dia escolhido."}
    </div>
);

const TurnoRecorrencia = () => {
    const { lojaEscolhidaState, horariosDisponiveisState } = useSelector(state => state);
    const { fetchHoursAvailable, hoursAvailable, isLoading } = useFetchHoursAvailable();
    const { setValue, register, watch } = useFormContext();

    const dataEntrega = watch('DataEntrega');

    const [descHorario, setDescHorario] = useState('');
    const [selectedField, setSelectedField] = useState(null);

    useEffect(() => {
        fetchHoursAvailable(
            lojaEscolhidaState.data.cod_siac,
            new Date(dataEntrega),
            //moment(dataEntrega).format('YYYY-MM-DD'),
        );
    }, [dataEntrega]);


    const isChecked = (option) => {
        return selectedField === option;
    };

    const getContainerClassName = (option) => {
        if (isChecked(option)) {
            return 'radioBtnSubstituicao radioSelected';
        }

        return 'radioBtnSubstituicao';
    };

    const handleClick = (option) => {
        setSelectedField(option);
    }

    const handleChange = (value) => {
        setDescHorario(value);
        setValue("HoraEntrega", value);
    };

    const getDescHorario = () => {
        return descHorario;
    };

    const isToday = (dataEntrega === moment().format("YYYY-MM-DD"));

    const isStoreOpen =
        hoursAvailable.length > 0 &&
        (!horariosDisponiveisState.data.substituicaoLoja.lojaFechada ||
            (horariosDisponiveisState.data.substituicaoLoja.lojaFechada &&
                horariosDisponiveisState.data.substituicaoLoja.cod_siac_substituta));

    const isStoreClosed =
        (horariosDisponiveisState.data.substituicaoLoja.lojaFechada &&
            !horariosDisponiveisState.data.substituicaoLoja.cod_siac_substituta);


    return (
        <>
            <div className='radioContainer mb6'  >
                {
                    isLoading ? (
                        <LoadingInline />
                    ) :
                        isStoreOpen ? (
                            <>
                                <Label className="textAvulso mb2">
                                    <b>
                                        Escolha o horário
                                    </b>
                                </Label>
                                {
                                    hoursAvailable.map(horario => (
                                        <div key={horario.CodHorario} className={getContainerClassName(horario.CodHorario)}
                                            onClick={() => handleClick(horario.CodHorario)}>
                                            <Label className="radioBtn">
                                                <Input
                                                    type="radio"
                                                    innerRef={register({ required: true })}
                                                    name="CodHorario"
                                                    value={horario.CodHorario}
                                                    onChange={() => handleChange(horario.DescHorario)}
                                                    className="mx-0"
                                                />
                                                <div className=" textRadioBtnWithContainer textAvulso">
                                                    {horario.DescHorario}
                                                </div>
                                            </Label>
                                        </div>
                                    ))

                                }
                            </>
                        ) : (
                            isStoreClosed ? (
                                <StoreClosedInformation />
                            ) : (
                                <StoreAvailableInformation isToday={isToday} />
                            )
                        )
                }
                <Label>
                    <Input
                        type="hidden"
                        innerRef={register({ required: true })}
                        name="DescHorario"
                        id="DescHorario"
                        value={getDescHorario()}
                        readOnly
                    />
                </Label>
            </div>
        </>
    )
}

export default TurnoRecorrencia;