import PedidoEnviadoInterface from "../interfaces/PedidoEnviadoInterface";
import PedidoEnviadoRecorrenciaInterface from "../interfaces/PedidoEnviadoRecorrenciaInterface";
import { RequestHorariosDisponiveisUpdateInterface } from "../interfaces/RequestHorariosDisponiveisUpdateInterface";
import Auth from "../models/Auth";
import Cliente from "../models/Cliente";
import ClienteEndereco from "../models/ClienteEndereco";
import Horario from "../models/Horario";
import Loja from "../models/Loja";
import { Pedido } from "../models/Pedido";
import Recorrencia from "../models/Recorrencia";
import SubstituicaoLoja from "../models/SubstituicaoLoja";
import * as ACTIONS from "./actionTypes";

export const clienteUpdate = (value: Cliente) => ({
  type: ACTIONS.CLIENTE_UPDATE_VALUE,
  data: value,
});

export const authUpdate = (value?: Auth) => ({
  type: ACTIONS.AUTH_UPDATE,
  data: value,
});

export const userLogout = () => ({
  type: ACTIONS.USER_LOGOUT,
});

export const pedidoUpdate = (value: Pedido) => ({
  type: ACTIONS.PEDIDO_UPDATE,
  data: value,
});

export const pedidoSetEndereco = (value: ClienteEndereco) => ({
  type: ACTIONS.PEDIDO_SET_ENDERECO,
  data: value,
});

export const pedidoSetLoja = (value: string) => ({
  type: ACTIONS.PEDIDO_SET_LOJA,
  data: value,
});

export const pedidoSetRepeticao = (value: Pedido) => ({
  type: ACTIONS.PEDIDO_SET_REPETICAO,
  data: value,
});

export const recorrenciaUpdate = (value: Recorrencia) => ({
  type: ACTIONS.RECORRENCIA_UPDATE,
  data: value,
});

export const recorrenciaSetEndereco = (value: ClienteEndereco) => ({
  type: ACTIONS.RECORRENCIA_SET_ENDERECO,
  data: value,
});

export const recorrenciaSetLoja = (value: string) => ({
  type: ACTIONS.RECORRENCIA_SET_LOJA,
  data: value,
});

export const recorrenciaSetRepeticao = (value: Recorrencia) => ({
  type: ACTIONS.RECORRENCIA_SET_REPETICAO,
  data: value,
});

export const lojaEscolhidaUpdate = (value: Loja) => ({
  type: ACTIONS.LOJA_ESCOLHIDA_UPDATE,
  data: value,
});

export const horariosDisponiveisUpdate = (value: Horario[]) => ({
  type: ACTIONS.HORARIOS_DISPONIVEIS_UPDATE,
  data: value,
});

export const requestHorariosDisponiveis = (
  value: RequestHorariosDisponiveisUpdateInterface
) => {
  return {
    type: ACTIONS.REQUEST_HORARIOS_DISPONIVEIS,
    data: value,
  };
};

export const successHorariosDisponiveis = (
  entrega: Horario[],
  retirada: Horario[],
  retiradaArmario: Horario[],
  entregaExpressa: Horario[],
  substituicaoLoja: SubstituicaoLoja
) => {
  return {
    type: ACTIONS.SUCCESS_HORARIOS_DISPONIVEIS,
    data: {
      entrega: entrega,
      retirada: retirada,
      retiradaArmario: retiradaArmario,
      entregaExpressa: entregaExpressa,
      substituicaoLoja: substituicaoLoja,
    },
  };
};

export const setHorariosDisponiveisEntregaOuRetirada = (
  value: "Entrega" | "Retirada"
) => {
  //console.log('setHorariosDisponiveisEntregaOuRetirada.value', value);
  return {
    type: ACTIONS.SET_HORARIOS_DISPONIVEIS_ENTREGA_OU_RETIRADA,
    data: value,
  };
};

export const failureHorariosDisponiveis = () => {
  return {
    type: ACTIONS.FAILURE_HORARIOS_DISPONIVEIS,
  };
};

export const pedidoEnviadoUpdate = (value: PedidoEnviadoInterface) => ({
  type: ACTIONS.PEDIDO_ENVIADO,
  data: value,
});

export const PedidoEnviadoRecorrenciaUpdate = (
  value: PedidoEnviadoRecorrenciaInterface
) => ({
  type: ACTIONS.PEDIDO_RECORRENCIA_ENVIADO,
  data: value,
});

export const setAmbiente = (value: "b2b" | "normal") => ({
  type: ACTIONS.SET_AMBIENTE,
  data: value,
});

export const setHorariosRecorrencia = (value: Horario[]) => ({
  type: ACTIONS.SET_HORARIOS_RECORRENCIA,
  data: value
})
