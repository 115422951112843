import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { Input, Label } from "reactstrap";
import { FormaPagamentoInterface } from "../../interfaces/FormaPagamentoInterface";
import Pedido from "../../models/Pedido";
import Ghost from "./Entrega/GhostComponent";

interface FormaDePagamentoComponentProps {
  children: never[];
  imagesCard: any[];
  didFetchParams: boolean;
  aceitaDinheiro: boolean | undefined;
  aceitaViaLink: boolean | undefined;
}

const FormaPagamento = ({
  aceitaDinheiro,
  aceitaViaLink,
  didFetchParams,
  imagesCard,
}: FormaDePagamentoComponentProps) => {
  const [opcoes, setOpcoes] = useState<FormaPagamentoInterface[]>([]);

  const [selectedField, setSelectedField] = useState(null);
  const { register, setValue, watch, errors } = useFormContext();
  const { pedidoState } = useSelector((state: any) => state);

  const watchFormaPagamento = watch("formaPagamento");

  const isChecked = (option: any) => {
    return selectedField === option;
  };

  const handleClick = (option: any) => {
    setSelectedField(option);
  };

  const getContainerClassName = (option: any) => {
    if (isChecked(option)) {
      return "radioBtnSubstituicao radioSelected";
    }

    return "radioBtnSubstituicao";
  };

  useEffect(() => {
    let dOpcoes: FormaPagamentoInterface[] = [
      {
        label: "Cartão de débito/crédito ",
        description: "na entrega",
        value: "Cartão Debito/Credito",
      },
      {
        label: "Voucher/alimentação ",
        description: "na entrega",
        value: "Voucher/Cartão alimentação",
      },
      {
        label: "Pagamentos online ",
        description: "enviamos via Whatsapp",
        value: "Via Link",
        img: imagesCard || [],
      },
      {
        label: "Dinheiro ",
        description: "na entrega",
        value: "Dinheiro",
      },
    ];

    if (!aceitaDinheiro) {
      dOpcoes = dOpcoes.filter((item) => item.value !== "Dinheiro");
    }

    if (!aceitaViaLink) {
      dOpcoes = dOpcoes.filter((item) => item.value !== "Via Link");
    }

    setOpcoes(dOpcoes);
  }, [aceitaDinheiro, aceitaViaLink, imagesCard]);

  useEffect(() => {
    const initForm = () => {
      const pedido: Pedido = new Pedido(pedidoState.data);
      pedido
        .transformToForm(["formaPagamento", "ValorDinheiro"])
        .forEach((item: any) => {
          setValue(item[0], item[1]);
        });
    };

    initForm();
  }, [opcoes, pedidoState, setValue]);

  return (
    <div className="mb4">
      <p className="tituloBasicoAvulso mb4" id="pagamento">
        pagamento
      </p>
      <div className="textAvulso mb2">
        <b>Escolha a forma de pagamento</b>
      </div>
      <div
        className="radioContainer mb4"
        aria-invalid={errors.formaPagamento ? "true" : "false"}
      >
        {opcoes.map((opcao: FormaPagamentoInterface) => {
          return didFetchParams ? (
            <div
              key={opcao.value}
              className={getContainerClassName(opcao.value)}
              onClick={() => handleClick(opcao.value)}
            >
              <Label className="d-flex align-items-center mb0.5    radioBtn">
                <Input
                  type="radio"
                  innerRef={register({ required: true })}
                  name="formaPagamento"
                  value={opcao.value}
                  className="mx-0 my-0"
                />
                <div className="textAvulso textRadioBtnCard">
                  <div className="ml-0.2 textAvulso">
                    <div style={{ display: "flex", flexDirection: 'column' }}>
                      <span><b>{opcao.label}</b></span>
                      <span>{opcao.description}</span>
                    </div>
                    {!!opcao.img &&
                      (Array.isArray(opcao.img) && opcao.img?.length > 0 ? (
                        <div style={{ marginLeft: 24, alignSelf: 'center' }}>
                          {opcao.img.map((imgUrl: any, idx: number) => {
                            return (
                              <img
                                key={`${idx} - ${imgUrl}`}
                                src={imgUrl}
                                style={{ objectFit: "contain" }}
                                alt="Ilustração Forma Pagamento"
                                className="ml-2"
                              />
                            );
                          })}
                        </div>
                      ) : (
                        <img
                          src={opcao.img}
                          style={{ objectFit: "contain" }}
                          alt="Ilustração Forma Pagamento"
                          className="ml-2"
                        />
                      ))}
                  </div>
                </div>
              </Label>
            </div>
          ) : (
            <Ghost />
          );
        })}
      </div>

      {aceitaDinheiro && watchFormaPagamento === "Dinheiro" ? (
        <div className="mb2">
          <p className="textAvulso mb2">
            <b>Troco para quanto?</b>
          </p>
          <Input
            type="number"
            innerRef={register()}
            name="ValorDinheiro"
          ></Input>
        </div>
      ) : null}
    </div>
  );
};

export default FormaPagamento;
