import Swal from 'sweetalert2';

class AlertService {

    public static show(msg: string, title: string = 'Atenção', icon: any = null): Promise<any> {
        return Swal.fire({
            title: title,
            html: msg,
            imageWidth: 60,
            imageHeight: 60,
            showConfirmButton: true,
            backdrop: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            icon: icon,
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });
    }

    public static error(msg: string, title: string = '') {
        return this.show(msg, title, 'error');
    }

    public static success(msg: string, title: string = '') {
        return this.show(msg, title, 'success');
    }

}


export default AlertService;