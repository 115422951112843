import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #F3F3F3;
  border-radius: 8px;
  position: fixed;
  padding: 16px;
  position: sticky;
  top: 40px;
  
  nav {
    img {
      margin-right: 10px;
    }
  }
  @media (max-height: 685px) {
    height: 100vh;
    top: 5px;
    overflow-y: scroll;
  }
`;

export const Title = styled.p`
    font-family: Bellfort;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 16px;
    color: #54412F;
`;

export const Subitle = styled.p`
    font-family: OpenSans;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
    color: #54412F;
`;

export const DefaultButton = styled.a<{ separator?: boolean, avulso?: boolean, lastItem?: boolean }>`
    display: flex;
    font-family: OpenSans;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #54412F;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 8px;
    align-items: center;
    margin-bottom: ${({ separator, lastItem }) => !!lastItem ? 0 : !!separator ? "8px" : "4px"};

    &:hover {
      background-color: #CCCCCC;
      color: #54412F;
      text-decoration: none;
    }

    &:active {
      color: #FFFFFF;
      background-color: ${({ avulso }) => avulso ? "#26946F" : "#A35E8E"};;
      text-decoration: none;
       .defaultImg {
         display: none;
       }
       .whiteImg {
         display: block;
       }
    }

    
    .defaultImg {
      display: block;
    }
    .whiteImg {
      display: none;
    }
`;

export const Separator = styled.div`
    border-bottom: 2px dotted #BEBEBE;
    width: 100%;
    margin-bottom: 8px;
`;
