import * as ACTIONS from '../actions/actionTypes';

const initialState = {
    data: {}
}

export const recorrenciaReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.RECORRENCIA_UPDATE:
            return {
                ...state,
                data: action.data
            };
        case ACTIONS.RECORRENCIA_SET_ENDERECO:
            return {
                ...state,
                data: {
                    ...state.data,
                    Cep: action.data.CEP,
                    Endereco: action.data.logradouro,
                    Numero: action.data.Numero,
                    Complemento: action.data.Complemento,
                }
            };
        case ACTIONS.RECORRENCIA_SET_REPETICAO:
            return {
                ...state,
                data: {
                    ...state.data,
                    CodRecorrencia: action.data.CodRecorrencia,
                    Descricao: action.data.Descricao,
                    numCpfCnpj: action.data.numCpfCnpj,
                    Cep: action.data.Cep,
                    Endereco: action.data.Endereco,
                    Numero: action.data.Numero,
                    Complemento: action.data.Complemento,
                    FLV: action.data.FLV,
                    CarneAvePeixe: action.data.CarneAvePeixe,
                    OutrosProdutos: action.data.OutrosProdutos,
                    DHP: action.data.DHP,
                    Lanche: action.data.Lanche,
                    DataCadastroApi: action.data.DataCadastroApi,
                    LojaId: action.data.LojaId,
                    DataProximaEntrega: action.data.DataProximaEntrega,
                    RecorrenciaDia: action.data.RecorrenciaDia,
                    tipoContato: action.data.tipoContato,
                    StatusRecorrencia: action.data.StatusRecorrencia,
                    CodHorario: action.data.CodHorario,
                    CodMeioDeContato: action.data.CodMeioDeContato,
                    Nome: action.data.Nome,
                    QtdDiasAntMensagem: action.data.QtdDiasAntMensagem,
                    Substituicao: action.data.Substituicao,
                    formaPagamento: action.data.formaPagamento,
                    IncluirSacola: action.data.IncluirSacola,
                    TipoEntrega: action.data.TipoEntrega,
                    HoraEntrega: action.data.HoraEntrega,
                    DataCancelamento: action.data.DataCancelamento,
                    MotivoCancelamento: action.data.MotivoCancelamento,
                    ReferenciaEndereco: action.data.ReferenciaEndereco,
                    Telefone: action.data.Telefone,
                    ComoEntregar: action.data.ComoEntregar,
                }
            };
        case ACTIONS.RECORRENCIA_SET_LOJA:
            return {
                ...state,
                data: {
                    ...state.data,
                    LojaId: action.data
                }
            };
        default:
            return state;
    }
}