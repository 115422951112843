import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';


const ProximaEntregaDisponivel = (props: any) => {
    const { listaHorarios } = props;
    const { watch } = useFormContext();
    const [textoProximaEntrega, setTextoProximaEntrega] = useState('');

    const { horariosDisponiveisState } = useSelector((state: any) => state);
    const watchDataEntrega = watch('DataEntrega');

    useEffect(() => {
        const getTextoProximaEntrega = () => {
            let texto = '';

            if (horariosDisponiveisState.data.entrega.length === 0
                || (horariosDisponiveisState.data.entrega.length > 0 && moment(watchDataEntrega).isAfter(moment()))
                || (horariosDisponiveisState.data.substituicaoLoja.lojaFechada && !horariosDisponiveisState.data.substituicaoLoja.cod_siac_substituta)
            ) {
                //sem horarios no dia, primeiro horário do dia seguinte
                texto = moment().add(1, 'day').format('DD/MM/YYYY') + ' às ' + listaHorarios[0]?.DescHorario.substring(0, 4);
            } else {
                texto = moment().format('DD/MM/YYYY') + ' às ' + (horariosDisponiveisState.data.entrega.length > 0 ? horariosDisponiveisState.data.entrega[0].DescHorario.substring(0, 4) : '');
            }

            return texto;
        }

        setTextoProximaEntrega(getTextoProximaEntrega());
    }, [horariosDisponiveisState, watchDataEntrega, listaHorarios])

    const ghostComponent = () => {
        return (
            <Skeleton
                width="25%"
                height="10px"
            />
        );
    };

    return (
        <>
            <div className="textAvulso mb1">
                <b>Próxima entrega disponível em:</b>
            </div>
            {
                horariosDisponiveisState.loading || !listaHorarios.length
                    ? ghostComponent()
                    : (
                        <div className="d-flex align-items-end mb3">
                            <div>
                                <img src="/assets/images/relogio-azul.png" alt="Relógio" height="22" />
                            </div>
                            <div className="textAvulso">{textoProximaEntrega}</div>
                        </div>
                    )
            }
        </>
    );
}

export default ProximaEntregaDisponivel;