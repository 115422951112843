import Pedido from "./Pedido";

export class PedidoRequest extends Pedido {
    SubstituicaoItemAItem: number;

    // adicionei esses 3 atributos
    FormaPagamento: string;
    DataProximaEntrega: Date;
    RecorrenciaDia: number;
}

export default PedidoRequest;