import * as ACTIONS from '../actions/actionTypes';

const initialState = {
  data: {}
};

export const lojaEscolhidaReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.LOJA_ESCOLHIDA_UPDATE:
      return {
        ...state,
        data: action.data
      };
    default:
      return state;
  }
}