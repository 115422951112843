import { Environments } from "interfaces/Environment";

const firebase = {
  apiKey: "AIzaSyDlVdMbcl01p2Q671rEgPwXfcq_kLDi8vM",
  authDomain: "hortifruti-276414.firebaseapp.com",
  projectId: "hortifruti-276414",
  storageBucket: "hortifruti-276414.appspot.com",
  messagingSenderId: "572328377897",
  appId: "1:572328377897:web:00541ca9df052837c5e638",
  measurementId: "G-76K4SDGCH8"
};


const environments: Environments = {
  development: {
    title: "Desenvolvimento",
    pedidoUrl: "http://192.168.2.7:7000/api/",
    externaUrl: "http://192.168.2.7:8091/api/",
    firebase: {
      apiKey: "AIzaSyCSLLQ_K82xmtnsOFGT-vDxfguSEa13qR0",
      authDomain: "teste-wpp-66de4.firebaseapp.com",
      projectId: "teste-wpp-66de4",
      storageBucket: "teste-wpp-66de4.appspot.com",
      messagingSenderId: "902399095692",
      appId: "1:902399095692:web:0cad85d748cb34a11edca9",
      measurementId: "G-4D4WK75EGG"
    },
  },
  developmentBkp: {
    title: "Desenvolvimento",
    pedidoUrl: "http://192.168.2.7:8089/api/",
    externaUrl: "http://192.168.2.7:8091/api/",
    firebase: {
      apiKey: "AIzaSyCSLLQ_K82xmtnsOFGT-vDxfguSEa13qR0",
      authDomain: "teste-wpp-66de4.firebaseapp.com",
      projectId: "teste-wpp-66de4",
      storageBucket: "teste-wpp-66de4.appspot.com",
      messagingSenderId: "902399095692",
      appId: "1:902399095692:web:0cad85d748cb34a11edca9",
      measurementId: "G-4D4WK75EGG"
    },
  },
  homologacao: {
    title: "Homologação",
    pedidoUrl: "http://10.254.254.9:8089/api/",
    externaUrl: "http://10.254.254.9:8091/api/",
    firebase: {
      apiKey: "AIzaSyCSLLQ_K82xmtnsOFGT-vDxfguSEa13qR0",
      authDomain: "teste-wpp-66de4.firebaseapp.com",
      projectId: "teste-wpp-66de4",
      storageBucket: "teste-wpp-66de4.appspot.com",
      messagingSenderId: "902399095692",
      appId: "1:902399095692:web:0cad85d748cb34a11edca9",
      measurementId: "G-4D4WK75EGG"
    },
  },
  preproduction: {
    title: "Pré-produção",
    pedidoUrl: "http://10.254.254.4:8096/api/",
    externaUrl: "http://10.254.254.4:8094/api/",
    firebase,
  },
  production: {
    title: "Produção",
    pedidoUrl: "https://wpp.hortifruti.com.br:8089/api/",
    externaUrl: "https://wpp.hortifruti.com.br:8093/api/",
    firebase: {
      apiKey: "AIzaSyDlVdMbcl01p2Q671rEgPwXfcq_kLDi8vM",
      authDomain: "hortifruti-276414.firebaseapp.com",
      projectId: "hortifruti-276414",
      storageBucket: "hortifruti-276414.appspot.com",
      messagingSenderId: "572328377897",
      appId: "1:572328377897:web:00541ca9df052837c5e638",
      measurementId: "G-76K4SDGCH8",
    },
  },
};

const environment: string = process.env.REACT_APP_ENVIRONMENT || "";

export default environments[environment];

export const googleAnalytics = "GTM-WKVS43X";

export const googleUA = "UA-195681499-1";

export const hotjarId = "2178534";
export const hotjarSv = "6";
