export const CLIENTE_UPDATE_VALUE = 'CLIENTE_UPDATE_VALUE';
export const AUTH_UPDATE = 'AUTH_UPDATE';
export const PEDIDO_UPDATE = 'PEDIDO_UPDATE';
export const PEDIDO_SET_LOJA = 'PEDIDO_SET_LOJA';
export const PEDIDO_SET_CEP = 'PEDIDO_SET_CEP';
export const PEDIDO_SET_ENDERECO = 'PEDIDO_SET_ENDERECO';
export const PEDIDO_SET_REPETICAO = 'PEDIDO_SET_REPETICAO';
export const RECORRENCIA_UPDATE = 'RECORRENCIA_UPDATE';
export const RECORRENCIA_SET_LOJA = 'RECORRENCIA_SET_LOJA';
export const RECORRENCIA_SET_ENDERECO = 'RECORRENCIA_SET_ENDERECO';
export const RECORRENCIA_SET_REPETICAO = 'RECORRENCIA_SET_REPETICAO';
export const LOJA_ESCOLHIDA_UPDATE = 'LOJA_ESCOLHIDA_UPDATE';
export const HORARIOS_DISPONIVEIS_UPDATE = 'HORARIOS_DISPONIVEIS_UPDATE';
export const REQUEST_HORARIOS_DISPONIVEIS = 'REQUEST_HORARIOS_DISPONIVEIS';
export const SUCCESS_HORARIOS_DISPONIVEIS = 'SUCCESS_HORARIOS_DISPONIVEIS';
export const FAILURE_HORARIOS_DISPONIVEIS = 'FAILURE_HORARIOS_DISPONIVEIS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const PEDIDO_ENVIADO = 'PEDIDO_ENVIADO';
export const PEDIDO_RECORRENCIA_ENVIADO = 'PEDIDO_RECORRENCIA_ENVIADO';
export const SET_HORARIOS_DISPONIVEIS_ENTREGA_OU_RETIRADA = 'SET_HORARIOS_DISPONIVEIS_ENTREGA_OU_RETIRADA';
export const SET_AMBIENTE = 'SET_AMBIENTE';
export const SET_HORARIOS_RECORRENCIA = 'SET_HORARIOS_RECORRENCIA'