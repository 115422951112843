import React from 'react';
import Skeleton from 'react-loading-skeleton';


const Ghost = () => {
    return (
        <div style={{ padding: '4px', }}>
            <Skeleton
                width="14px"
                height="14px"
                inline circle
                style={{ marginRight: '8px' }}
            />
            <Skeleton width="157px" height="16px" />
        </div>
    );
};

export default Ghost;