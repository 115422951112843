import axios from "axios";
import axiosRetry from "axios-retry";
import environment from "config/environment";

const apiPedido = axios.create({
  baseURL: environment.pedidoUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

apiPedido.interceptors.request.use(
  async (config) => {
    return Promise.resolve(config);
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosRetry(apiPedido, { retries: 30, retryDelay: () => 6000 });

export default apiPedido;
