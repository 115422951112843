import { store } from './store';


const isAutenticado = (): boolean => {
    const { authState } = store.getState();

    return ((authState.data.Cpf || authState.data.Email || authState.data.Telefone))
        ? true : false;
}


export default isAutenticado;