import React, { useState } from 'react';
import { Label, Input } from 'reactstrap';
import RadioComponent from './RadioComponent';

import { useFormContext } from 'react-hook-form';



const InputRadioSubstituicao = (props: any) => {

    const { register, errors, watch } = useFormContext();
    const [selectedField, setSelectedField] = useState(null);

    const cancele: number = 1;
    const escolha: number = 2;
    const contato: number = 3;

    // useEffect(() => {
    //     if (watchFieldValue !== null && watchFieldValue !== undefined) {
    //         persistFieldContentOnLocalStorage();
    //     };

    // }, [watchFieldValue]);

    // useEffect(() => {
    //     const hasStoragedValue = localStorage.getItem("Substituicao");
    //     if (hasStoragedValue) {
    //         setTimeout(() => {
    //             setValue("Substituicao", hasStoragedValue);
    //         }, 1500);
    //     }
    // }, [])

    // const persistFieldContentOnLocalStorage = () => {
    //     localStorage.setItem("Substituicao", watchFieldValue);
    // }

    const isChecked = (option: any) => {
        return selectedField === option;
    };

    const getContainerClassName = (option: any) => {
        if (isChecked(option)) {
            return 'radioBtnSubstituicao radioSelected';
        }

        return 'radioBtnSubstituicao';
    };

    const handleClick = (option: any) => {
        setSelectedField(option);
    }


    return (
        <div className='mb4'>
            <div className="textAvulso requerido">
                <b>
                    Se faltar um item, quero que a loja: 
                </b>
            </div>
            <div
                className='radioContainer mb6'
                aria-invalid={errors["Substituicao"] ? 'true' : 'false'}
            >
                <div
                    className={getContainerClassName(escolha)}
                    onClick={() => handleClick(escolha)}
                >
                    <Label>
                        <Input
                            type="radio"
                            name={"Substituicao"}
                            innerRef={register({ required: true })}
                            value={escolha}
                        //className="ml-0"
                        />
                        <div className="textAvulso textRadioBtnWithContainer" >
                            <div className='substituicaoOption'>
                                <strong>Escolha outro por mim</strong>
                            </div>
                            <div className="substituicaoText" style={{ marginLeft: '22px', display: 'block' }}>
                                Na falta de um item, a loja escolhe um similar e faz a troca para você
                            </div>
                        </div>
                    </Label>
                </div>
                <div
                    className={getContainerClassName(contato)}
                    onClick={() => handleClick(contato)}
                >
                    <Label>
                        <Input
                            type="radio"
                            name={"Substituicao"}
                            innerRef={register({ required: true })}
                            value={contato}

                        //className="ml-0"
                        />
                        <div className="textAvulso textRadioBtnWithContainer" >
                            <div className='substituicaoOption'>
                                <strong>Entre em contato comigo</strong>
                            </div>
                            <div className="substituicaoText" style={{ marginLeft: '22px', display: 'block' }}>
                                A loja entra em contato e você escolhe outro item
                            </div>
                        </div>
                    </Label>
                </div>
                <div
                    className={getContainerClassName(cancele)}
                    onClick={() => handleClick(cancele)}
                >
                    <Label>
                        <Input
                            type="radio"
                            name={"Substituicao"}
                            innerRef={register({ required: true })}
                            value={cancele}
                        //className="ml-0"
                        />
                        <div className="textAvulso textRadioBtnWithContainer" >
                            <div className='substituicaoOption'>
                                <strong>Cancele o item</strong>
                            </div>
                            <div className="substituicaoText" style={{ marginLeft: '22px', display: 'block' }}>
                                O item em falta é retirado do seu pedido
                            </div>
                        </div>
                    </Label>
                </div>
            </div >
        </div>
    );
}

export default InputRadioSubstituicao;