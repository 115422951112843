import orderBy from 'lodash.orderby';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Button, Row, Col } from 'reactstrap';
import { lojaEscolhidaUpdate, pedidoSetRepeticao } from '../../actions';
// import Loja from '../../models/Loja';
// import Pedido from '../../models/Pedido';
// import StatusPedido from '../../models/StatusPedido';
import LoadingService from '../../services/LoadingService';
import AnimationTransition from "../../components/PreAtendimento/SelecaoLoja/AnimationTransition";
import LojaService from '../../services/LojaService';
import PedidoService from '../../services/PedidoService';
import TagManager from "react-gtm-module";
import '../style.css';
import moment from 'moment';

const ConsultaStatusPedidos = (props) => {
    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: "virtualPageView",
                page: "/consulta-status-pedidos",
                etapa: "status-pedidos",
                dominio: window.location.href.split('/con')[0]
            },
        });
    }, []);

    useEffect(() => {
        const img = new Image();
        img.src = '/src/assets/animations/orange-walker.gif';
      }, [])
    
    const { clienteState } = useSelector((state) => state);
    const [pedidos, setPedidos] = useState([]);
    const [lojas, setLojas] = useState([]);
    const [statusPedidos, setStatusPedidos] = useState([]);
    const history = useHistory();
    let { apenasConcluido } = useParams();
    const dispatch = useDispatch();
    const [isLoadingOrderTypes, setIsLoadingOrderTypes] = useState(true);


  useEffect(() => {

    return () => {
      LoadingService.hide()
      setIsLoadingOrderTypes(false);
    }
  }, [])

   

    useEffect(() => {
        async function inicializar() {
            

            const lojas = await LojaService.listaLojas();

            setLojas(lojas);

            const statusPedidos = await PedidoService.listaStatus();

            setStatusPedidos(statusPedidos);

            let pedidos = await PedidoService.listaPedidosPorCpf(clienteState.data.Cpf);

            if (apenasConcluido) {
                pedidos = pedidos.filter((pedido) => pedido.isConcluido());
            }

            setPedidos(orderBy(pedidos, 'DataEntrega', 'desc'));

            setIsLoadingOrderTypes(false);
            
        }

        inicializar();
    }, [clienteState.data.Cpf, apenasConcluido]);

    const repetirPedido = (pedido) => {
        dispatch(pedidoSetRepeticao(pedido));

        const loja = LojaService.obterLojaPeloCod(lojas, pedido.LojaId);

        dispatch(lojaEscolhidaUpdate(loja));

        history.push('/pedido')
    }

    const pedidosAndamento = pedidos
        .filter(i => i.StatusPedido < 5)
        .filter(o => moment(o.DataEntrega, 'YYYY-MM-DD')
            .isBetween(moment().subtract(2, 'months')) || moment(o.DataEntrega).isAfter());
    const pedidosFinalizados = pedidos.filter(i => i.StatusPedido > 4);

    const getAnimation = () => {
        return (
          <AnimationTransition renderPhrases={true} />
        )
      }
    return (
        isLoadingOrderTypes ? getAnimation() : (
        <div className="container-hortifruti my-3 w-100">
            <div className='mb5 listItem'>
                <Row className='d-flex justify-content-between w-100 mx-0 mb2'>
                    <Col xs={12}>
                        <p className='listItemText mb2'>
                            Você tem <b>{pedidosAndamento.length}</b> {pedidosAndamento.length > 1 ? "pedidos" : "pedido"} em andamento:
                        </p>
                    </Col>
                    <Col xs={12} className='d-flex justify-content-center mb2'>
                        <div style={{
                            border: '1px solid #D4D4D4', width: '98%'
                        }} />
                    </Col>
                </Row>
                {pedidosAndamento.map((pedido, idx) => {
                    return (
                        <Row className='d-flex justify-content-between w-100 mx-0 mb2' key={pedido.Id}>
                            <Col xs={7}>
                                <p className='listItemTextBold mb1 brown'><Moment locale="pt-br" format="DD/MM/YYYY">{pedido.DataEntrega}</Moment> - {pedido.DescHorario}</p>
                                <p className='listItemStatus mb3 greenDark'>{pedido.StatusNewtail ?? PedidoService.obterStatusPeloId(statusPedidos, pedido.StatusPedido).Nome}</p>
                            </Col>

                            <Col xs={5} className='listItemTextBold' style={{ textAlign: 'end', color: '#D6CABC' }}>
                            </Col>
                            {idx < (pedidosAndamento.length - 1) && (
                                <Col xs={12}>
                                    <div style={{
                                        border: '1px solid #D4D4D4'
                                    }} />
                                </Col>
                            )}
                        </Row>
                    )
                })}
            </div>
            <div className='mb5'>
                <p className='mb2 listTitle brown'>Pedidos anteriores:</p>
                {pedidosFinalizados.map((pedido) => {
                    return (
                        <Row className='d-flex justify-content-between w-100 listItem mx-0 mb2' key={pedido.Id}>
                            <Col xs={7}>
                                <p className='listItemTextBold mb3 brown'><Moment locale="pt-br" format="DD/MM/YYYY">{pedido.DataEntrega}</Moment></p>
                                <p className='listItemText brown'>{pedido.Endereco + ', ' + pedido.Numero}</p>
                            </Col>

                            <Col xs={5} className='listItemTextBold' style={{ textAlign: 'end', color: '#D6CABC' }}>
                                {pedido.isConcluido()
                                    ? <Button type="button" color="primary" onClick={() => repetirPedido(pedido)}>
                                        <p className='listItemText white'>
                                            Repetir
                                        </p>
                                    </Button>
                                    : PedidoService.obterStatusPeloId(statusPedidos, pedido.StatusPedido).Nome
                                }
                            </Col>
                        </Row>
                    )
                })}
            </div>
            <div className="d-flex ButtonSelect">
                <button
                    type="button"
                    className="greenButton SelectLoja"
                    color="primary"
                    onClick={() => history.goBack()}
                >
                    Voltar
                </button>
            </div>
        </div>
        )
    )
}


export default ConsultaStatusPedidos;