import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;

  img {
    // width: 100%;
    margin: 0 auto;
    display: block;
  }

  textarea {
    margin-bottom: 32px
  }
`;

export const SectionTitle = styled.h2`
  font-family: Abel;
  font-weight: normal;
  font-size: 32px;
  line-height: 34px;
  margin-bottom: 30px;
`;

export const SectionTitleList = styled.h2`
  font-family: Bellfort;
  font-size: 24px;
  color: #54412F;
  margin: 0;
  font-weight: bold;
  margin-bottom: 12px;
`;