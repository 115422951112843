import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import ParametroService from '../../services/ParametroService';


// Param 33 (Tipos de lojas): 0 = Fake, 1 = Full, 2 = Dark, 3 = Leve.
const InformacoesImportantes = ({ parametrosLoja }: any) => {
    const [msgFrete, setMsgFrete] = useState<string | null>(null);
    const [msgHorarios, setMsgHorarios] = useState<string | null>(null);
    const [msgEntregaExpressa, setMsgEntregaExpressa] = useState<string | null>(null);
    const [msgFormasDePagamento, setMsgFormasDePagamento] = useState<string | null>(null);
    const [isInfoLoaded, setIsInfoLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!!parametrosLoja.length) {
            setMsgHorarios(ParametroService.getTxtHorariosDeEntrega(parametrosLoja));
            setMsgFormasDePagamento(ParametroService.getTxtFormasDePagto(parametrosLoja));
            setMsgEntregaExpressa(ParametroService.getTxtEntregaExpressa(parametrosLoja));
            setMsgFrete(ParametroService.getTxtFrete(parametrosLoja));
            setIsInfoLoaded(true);
        }
    }, [parametrosLoja])

    const ghostComponent = () => {
        return (
            <>
                <Skeleton
                    width="90%"
                    height="10px"
                />
                <Skeleton
                    width="65%"
                    height="10px"
                />
            </>
        )
    }

    return (
        <div className="container-textAvulso mb6">
            <div className="mb3">
                <div className="mb1">
                    <b>Formas de Pagamento</b> 💳<br />
                </div>
                {
                    isInfoLoaded ?
                        <p>{msgFormasDePagamento?.replace('??', '')} 🍎</p> :
                        ghostComponent()
                }
            </div>
            <div className="mb3">
                <div className="mb1">
                    <b>Horário de entrega</b> 🛵<br />
                </div>
                {
                    isInfoLoaded ?
                        <p>{msgHorarios?.replace('??', '')} 💨<br /></p> :
                        ghostComponent()
                }
            </div>
            <div className="mb3">
                <div className="mb1">
                    <b>Frete</b> 🥰<br />
                </div>
                {
                    isInfoLoaded ?
                        <p>{msgFrete?.replace('??', '')} 🥰<br /></p> :
                        ghostComponent()
                }
            </div>
            {
                msgEntregaExpressa && (
                    <div className="mb3">
                        <div className="mb1">
                            <b>Entrega Expressa</b> 🛵<br />
                        </div>
                        {msgEntregaExpressa.replace('??', '')} 🛵<br />
                    </div>
                )
            }

            <b>{ParametroService.getPedidoMinimoInfo(parametrosLoja)}</b><br />

        </div>
    );
}

export default InformacoesImportantes;