export class PedidoResponse {
    status: boolean
    codPedido: string
    error: string;
    
    constructor(init?:Partial<PedidoResponse>){
        Object.assign(this, init);
    }

}

export default PedidoResponse;