import Cliente from "../models/Cliente";
import LeveNatural from "../models/LeveNatural";
import Loja from "../models/Loja";
import apiExterna from "./apis/ApiExterna";
import apiPedido from "./apis/ApiPedido";

class ClienteService {

    /**
     * Carrega um cliente pelo cpf
     * @param cpf 
     * @returns 
     */
    static async carregarPorCpf(cpf: string): Promise<Cliente> {
        const res = await apiPedido.get('Cliente/CarregarPorCpf/' + cpf);
        const cliente = new Cliente(res.data.valor);
        return cliente;
    }

    /**
     * Carrega dados do cliente pelo email
     * @param email
     * @returns 
     */
    static async carregarPorEmail(email: string): Promise<Cliente> {
        const res = await apiPedido.get('Cliente/CarregarPorEmail?email=' + email);
        const cliente = new Cliente(res.data.valor);
        return cliente;
    }

    /**
     * Carrega dados do cliente pelo telefone
     * @param telefone
     * @returns 
     */
    static async carregarPorTelefone(telefone: string): Promise<Cliente> {
        const res = await apiPedido.get('Cliente/CarregarPorTelefone?telefone=' + telefone);
        const cliente = new Cliente(res.data.valor);
        return cliente;
    }

    /**
     * Busca as lojas proximas do cep informado
     * @param body 
     */
    static async buscaLojasProximasCep(body: object): Promise<Loja[]> {
        const res = await apiPedido.post('Cliente/ListaLojasProximasdoCEPOrigem', body);

        let lojas: Loja[] = [];

        res.data.valor.map((loja: Loja) => lojas.push(loja))

        return lojas;
    }
    /**
     * Excluir endereço
     * @param cep 
     */
    static async excluirEndereco(body: object): Promise<Loja[]> {
        const res = await apiPedido.post('Pedidos/ExcluirEndereco', body);
        
        return res.data.value;
        // return lojas;
    }

    /**
     * Busca proximo horario disponivel
     * @param body 
     */
    static async listarProximoSlot(body: object): Promise<any[]> {
        const res = await apiPedido.post('Recorrencia/ListarProximoSlotDisponivel/', body);

        let data: any = [];

        res.data.valor.map((item: any) => data.push(item))

        return data;
    }

    static async getLeveNatural(documento: string): Promise<LeveNatural> {
        if (!documento) {
            console.warn('Documento não informado para consultar se é leve natural');
            return new LeveNatural({
                ativo: false
            });
        }

        const res = await apiExterna.post('Cliente/ELeveNatural?objeto=' + documento, {}, {
            'axios-retry': {
                retryCondition: () => true, //axios-retry não tenta de novo por padrão se for post
            }
        });

        const leveNatural = new LeveNatural({
            ativo: res.data.valor ? true : false,
            adesao: res.data.valor
        });

        return leveNatural;
    }

}

export default ClienteService;