import Horario from "models/Horario";
import { useDispatch } from 'react-redux';
import { useCallback, useState } from "react";
import RecorrenciaService from "services/RecorrenciaService";

import { setHorariosRecorrencia } from 'actions';

export const useFetchHoursAvailable = () => {
  const dispatch = useDispatch();
  const [hoursAvailable, setHoursAvailable] = useState<Horario[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchHoursAvailable = useCallback(async (code: string, date: Date) => {
    setIsLoading(true);

    const request =
      await RecorrenciaService.listaHorarioDisponivelEntregaPorData(
        code,
        date,
        true
      );

    setHoursAvailable(request);
    dispatch(setHorariosRecorrencia(request));

    setIsLoading(false);
  }, []);

  return {
    hoursAvailable,
    fetchHoursAvailable,
    isLoading
  };
};
