export class MeioDeContato {
    CodMeioDeContato: number;
    DescMeioDeContato: string;
    IndAtivo: boolean;

    constructor(init?:Partial<MeioDeContato>){
        Object.assign(this, init);
    }

}

export default MeioDeContato;