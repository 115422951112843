import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Horario from '../../models/Horario';
import Parametro from '../../models/Parametro';
import LojaService from '../../services/LojaService';
import RecorrenciaService from '../../services/RecorrenciaService';
import moment from 'moment';
import ParametroService from '../../services/ParametroService';
import InformacoesImportantes from './InformacoesImportantes';
import InputRadioStandardContainer from './InputRadioStandard';
import InputRadioSubstituicaoContainer from './InputRadioStandard/InputRadioSubstituicao';
import FormaPagamento from './FormaPagamento';
import ClienteService from '../../services/ClienteService';
import LeveNatural from '../../models/LeveNatural';
import Endereco from './Endereco';
import DadosPessoais from './DadosPessoais';
import Entrega from './Entrega';
import ProximaEntregaDisponivel from './ProximaEntregaDisponivel';
import Pedido from '../../models/Pedido';
import LoadingService from '../../services/LoadingService';
import ProgramaFidelidade from './ProgramaFidelidade';
import TelefoneService from '../../services/TelefoneService';
import CpfService from '../../services/CpfService';
import AlertService from '../../services/AlertService';
import PedidoRequest from '../../models/PedidoRequest';
import PedidoResponse from '../../models/PedidoResponse';
import PedidoService from '../../services/PedidoService';
import PedidoEnviadoInterface from '../../interfaces/PedidoEnviadoInterface';
import Footer from '../Layout/Footer';
import LGPDService from '../../services/LGPDService';
import Cupom from './Cupom';
import TextAreaField from '../TextAreaField/index';
import OfertasPersonalizadas from './Ofertas/index';
import VitrineCarousel from './VitrineCarousel';
import AnimationTransition from 'components/PreAtendimento/SelecaoLoja/AnimationTransition';
import IconApple from "assets/images/navbar/apple-alt.svg";
import IconAppleWhite from "assets/images/navbar/apple-alt-white.svg";
import IconMeat from "assets/images/navbar/meat.svg";
import IconMeatWhite from "assets/images/navbar/meat-white.svg";
import IconBread from "assets/images/navbar/bread-loaf.svg";
import IconBreadWhite from "assets/images/navbar/bread-loaf-white.svg";
import IconGlass from "assets/images/navbar/glass-cheers.svg";
import IconGlassWhite from "assets/images/navbar/glass-cheers-white.svg";
import IconTag from "assets/images/navbar/tag.svg";
import IconTagWhite from "assets/images/navbar/tag-white.svg";
import IconInfo from "assets/images/navbar/home.svg";
import IconInfoWhite from "assets/images/navbar/home-white.svg";
import IconMarker from "assets/images/navbar/shipping-fast.svg";
import IconMarkerWhite from "assets/images/navbar/shipping-fast-white.svg";
import IconAsterisk from "assets/images/navbar/cog.svg";
import IconAsteriskWhite from "assets/images/navbar/cog-white.svg";
import IconPayment from "assets/images/navbar/credit-card.svg";
import IconPaymentWhite from "assets/images/navbar/credit-card-white.svg";
import IconUser from "assets/images/navbar/user-circle.svg";
import IconUserWhite from "assets/images/navbar/user-circle-white.svg";
import IconCalendar from "assets/images/navbar/calendar-alt.svg";
import IconCalendarWhite from "assets/images/navbar/calendar-alt-white.svg";

import { FormProvider, useForm } from 'react-hook-form';
import { Button, Col, Container, Form, Input, Row } from 'reactstrap';

import * as Genericos from '../../hooks/Genericos';

import {
    PedidoEnviadoRecorrenciaUpdate, pedidoEnviadoUpdate, requestHorariosDisponiveis, setAmbiente, userLogout
} from '../../actions';
import { RequestHorariosDisponiveisUpdateInterface } from '../../interfaces/RequestHorariosDisponiveisUpdateInterface';
import { useHistory } from 'react-router';
import { useVirtualPageView, useInitiateOrderTracking, usePurchaseTracking } from '../../hooks/TagManager/index';
import { useOfertasPersonalizadas } from '../../hooks/useOfertasPersonalizadas';

import isAutenticado from '../../auth';

import RecorrenciaRequest from 'models/RecorrenciaRequest';

import 'react-loading-skeleton/dist/skeleton.css';
import './styles.scss';
import { useFetchHoursAvailable } from 'hooks/recorrencia/fetchHoursAvailable';
import FixedNavigator from 'Pages/Recorrencia/PedidoRecorrencia/components/FixedNavigator';


const PedidoComponent = ({ location }: any) => {
    const { fetchHoursAvailable } = useFetchHoursAvailable();

    const {
        authState,
        pedidoState,
        clienteState,
        lojaEscolhidaState,
        horariosDisponiveisState,
        horariosDisponiveisRecorrenciaState,
        ambienteState } = useSelector((state: any) => state);

    const { onVirtualPageView } = useVirtualPageView();
    const { onPurchase } = usePurchaseTracking();
    const { onInitiateOrder } = useInitiateOrderTracking();
    const { requestOfertasPersonalizadas, ofertas } = useOfertasPersonalizadas();

    const methods = useForm();

    const dispatch = useDispatch();
    const history = useHistory();

    const [allowSubstituicao, setAllowSubstituicao] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const substituicaoField = methods.watch('Substituicao');
    const frequency = methods.watch('frequency');

    useEffect(() => {
        switch (substituicaoField) {
            case '0':
                setAllowSubstituicao(false);
                methods.setValue('ItensSubstituicao', "");
                break;
            case '1':
                !allowSubstituicao && setAllowSubstituicao(true);
                break;
            case '2':
                !allowSubstituicao && setAllowSubstituicao(true);
                break;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [substituicaoField])

    useEffect(() => {
        onVirtualPageView({
            page: "/pedido",
            etapa: "pedido",
        });
    }, [onVirtualPageView]);

    useEffect(() => {
        onInitiateOrder({
            orderType: 'pedido-avulso'
        });
    }, [onInitiateOrder]);

    useEffect(() => {
        let cpf = authState?.data.Cpf;
        let id = pedidoState?.data.LojaId;

        requestOfertasPersonalizadas({ cpf, id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pedidoState.data.LojaId, authState.data.Cpf]);

    const [clienteLeveNatural, setClienteLeveNatural] = useState<LeveNatural>(new LeveNatural());
    const [listaHorarios, setListaHorarios] = useState<Horario[]>([]);
    const [parametrosLoja, setParametrosLoja] = useState<Parametro[]>([]);
    const [hasRecorrencia, setHasRecorrencia] = useState<boolean>(false);
    const [arrayImages, setArrayImages] = useState<any>('');
    const [arrayImagesCard, setArrayImagesCard] = useState<any>('');
    const [arrayImagesConfira, setArrayImagesConfira] = useState<any>('');
    const [arrayImagesPadaria, setarrayImagesPadaria] = useState<any>('');
    const [arrayImagesOutrosProdutos, setarrayImagesOutrosProdutos] = useState<any>('');
    const [arrayImagesProteinas, setarrayImagesProteinas] = useState<any>('');
    const [arrayImagesFLV, setarrayImagesFLV] = useState<any>('');

    const initHasRecorrencia = useCallback(async () => {
        RecorrenciaService.getHasRecorrencia(pedidoState.data.LojaId, 9)
            .then(res => setHasRecorrencia(res.data.hasRecorrencia))
            .catch(err => console.log(err));
    }, [pedidoState.data.LojaId]);

    const initClienteLeveNatural = useCallback(() => {
        ClienteService.getLeveNatural(authState.data.Cpf)
            .then(res => { setClienteLeveNatural(res) })
            .catch(err => console.error('error: ', err));
    }, [authState.data.Cpf])

    const initListaHorario = useCallback((): any => {
        RecorrenciaService.listaHorario()
            .then(lista => setListaHorarios(lista))
            .catch(err => console.error('error: ', err));
    }, []);

    const initParametrosLoja = useCallback((): any => {
        LojaService.listaParametros(pedidoState.data.LojaId)
            .then(params => setParametrosLoja(params))
            .catch(err => console.error('error ', err));
    }, [pedidoState.data.LojaId]);

    const initForm = useCallback(() => {
        const pedido: Pedido = new Pedido(pedidoState.data);
        pedido.Cpf = CpfService.formatar(authState.data.Cpf);
        pedido.Nome = clienteState.data.Nome;
        pedido.Telefone = TelefoneService.formatar(clienteState.data.Telefone);
        pedido.PreferenciasCliente = clienteState.data.Preferencias;
        pedido.transformToForm().forEach((item: any) => {
            methods.setValue(item[0], item[1]);
        });
        // removi o methods do array de dependências pois estava causando re-render looping
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [authState.data.Cpf, clienteState.data.Nome, clienteState.data.Preferencias, clienteState.data.Telefone, pedidoState.data]);


    useEffect(() => {
        const currentCarouselImages = ParametroService.getImagemMarketing(parametrosLoja);
        const currentCarouselImagesConfira = ParametroService.getImagemDHP(parametrosLoja);

        if (currentCarouselImages) {
            const currentArrayImages = currentCarouselImages.split(';');
            setArrayImages(currentArrayImages)
        }

        if (currentCarouselImagesConfira) {
            const currentArrayImages = currentCarouselImagesConfira.split(';');
            setArrayImagesConfira(currentArrayImages)
        }
    }, [parametrosLoja])


    useEffect(() => {
        function initOrder() {
            if (!pedidoState.data.LojaId) {
                if (isAutenticado()) {
                    history.push('/pre-atendimento');
                    AlertService.error('Antes de fazer o pedido, é necessário selecionar a loja.');
                } else {
                    history.push('/pre-atendimento');
                };
            }

            // requisições feitas em paralelo
            //initOfertasPersonalizadas();
            initListaHorario();
            initParametrosLoja();
            initClienteLeveNatural();
            initHasRecorrencia();

            // console.error('Houve um erro ao inicializar...');
            // AlertService.error('Sistema temporariamente indisponível. Por favor, tente novamente mais tarde.');

            initForm();
        }

        initOrder();
    }, [lojaEscolhidaState, authState, pedidoState, initListaHorario, initParametrosLoja, initForm, history, initClienteLeveNatural])

    useEffect(() => {
        const currentCarouselImages = ParametroService.getImagemMarketing(parametrosLoja);
        const currentCarouselImagesConfira = ParametroService.getImagemDHP(parametrosLoja);
        const currentCarouselImagesPadaria = ParametroService.getImagemPadaria(parametrosLoja);
        const currentCardlImages = ParametroService.getImagemCards(parametrosLoja);
        const currentCarouselImagesOutrosProdutos =
            ParametroService.getImagemOutrosProdutos(parametrosLoja);
        const currentCarouselImagesProteinas =
            ParametroService.getImagemPromocaoCarneAvePeixe(parametrosLoja);
        const currentCarouselImagesFLV = ParametroService.getImagemPromocaoFLV(parametrosLoja);

        if (currentCardlImages) {
            const currentArrayImages = currentCardlImages.split(';');
            checkIfImageExists(setArrayImagesCard, currentArrayImages);
        }

        if (currentCarouselImages) {
            const currentArrayImages = currentCarouselImages.split(';');
            checkIfImageExists(setArrayImages, currentArrayImages);
        }

        if (currentCarouselImagesConfira) {
            const currentArrayImages = currentCarouselImagesConfira.split(';');
            checkIfImageExists(setArrayImagesConfira, currentArrayImages);
        }
        if (currentCarouselImagesPadaria) {
            const currentArrayImages = currentCarouselImagesPadaria.split(';');
            checkIfImageExists(setarrayImagesPadaria, currentArrayImages);
        }
        if (currentCarouselImagesOutrosProdutos) {
            const currentArrayImages = currentCarouselImagesOutrosProdutos.split(';');
            checkIfImageExists(setarrayImagesOutrosProdutos, currentArrayImages);
        }
        if (currentCarouselImagesProteinas) {
            const currentArrayImages = currentCarouselImagesProteinas.split(';');
            checkIfImageExists(setarrayImagesProteinas, currentArrayImages);
        }
        if (currentCarouselImagesFLV) {
            const currentArrayImages = currentCarouselImagesFLV.split(';');
            checkIfImageExists(setarrayImagesFLV, currentArrayImages);
        }
    }, [parametrosLoja]);

    function checkIfImageExists(setter: any, arrayUrl: string[]) { // adicionado
        let arr: string[] = [];
        arrayUrl.forEach(url => {
            const img = new Image();
            img.src = url;

            if (img.complete) {
                arr.push(url);
            } else {
                img.onload = () => {
                    arr.push(url);
                };
                img.onerror = () => {
                    return;
                };
            }
        });
        setter(arr);
    }

    const getHorarioEntregaByCod = (codHorario: number, listaHorarios: Horario[]): Horario => {
        const horario = new Horario(listaHorarios.filter((item: Horario) => item.CodHorario === codHorario)[0]);
        return horario;
    }

    const isPedidoSwitchedToRecorrencia = useMemo((): boolean => {
        if (frequency) {
            const lastChange = frequency[frequency.length - 1];

            return lastChange.frequency;
        }

        return false;
    }, [frequency]);

    const deliveryDateNotProvided = (dataEntrega: any) => {
        return !dataEntrega;
    };

    /**
     * Confere se o pedido pode ser realizado na data/hora escolhida
     * @param dataEntrega 
     * @param codHorario 
     * @returns 
     */
    const podeRealizar = (
        dataEntrega: Date,
        codHorario: number,
        codLoja: string,
        entregaOuRetirada: "Entrega" | "Retirada",
        isEntregaExpressa: boolean,
        pedidoConvertido: boolean): boolean => {

        // verificação dupla ** - VERIFICAR
        if (!codHorario && !isEntregaExpressa) { // codHorario será undefined ainda em caso de pedido convertido
            return false;
        }

        let listaHorarios: Horario[] = [];
        // aqui ele monta a estrutura com os horários disponíveis
        if (pedidoConvertido) {
            listaHorarios = horariosDisponiveisRecorrenciaState.data;
        } else if (entregaOuRetirada === 'Retirada') {
            listaHorarios = horariosDisponiveisState.data.retirada;
        } else {
            listaHorarios = horariosDisponiveisState.data.entrega;
        }

        //  aqui terei a lista de horários atualizada?
        const horario: Horario = getHorarioEntregaByCod(codHorario, listaHorarios);

        // verificação dupla **
        if (!horario.CodHorario && !isEntregaExpressa) { // CodHorario apenas para Entrega a Domicílio (comum)
            return false;
        }

        // vai verificar se o usuário demorou para fechar o pedido 
        // e fazer a atualização dos horários
        const dthrEntrega = moment(dataEntrega).startOf('day').hour(horario.HorarioIncio);

        if (moment().isAfter(dthrEntrega) && !isEntregaExpressa) {

            if (pedidoConvertido) {
                fetchHoursAvailable(
                    lojaEscolhidaState.data.cod_siac,
                    new Date(dataEntrega),
                );
            } else {
                const busca: RequestHorariosDisponiveisUpdateInterface = {
                    codLoja: codLoja,
                    data: moment(dataEntrega).toDate(),
                    entregaOuRetirada: entregaOuRetirada
                };

                dispatch(requestHorariosDisponiveis(busca));
            }


            return false;
        }

        return true;
    }


    const checkOffers = ({ OfertaPersonalizada: ofertasField }: any) => {
        let formattedOfertas = ofertasField;

        return !!formattedOfertas.trim().length;
    };


    const getEntregaOuRetirada = (tipoEntrega: string) => {
        return tipoEntrega ===
            (
                Genericos.ENTREGA_DOMICILIO_NORMAL ||
                Genericos.ENTREGA_EXPRESSA
            ) ||
            isPedidoSwitchedToRecorrencia ?
            'Entrega'
            : 'Retirada';
    }

    const validacoesAdicionaisForm = function (data: any): boolean {
        let possuiErros = false;

        const entregaOuRetirada = getEntregaOuRetirada(data.TipoEntrega);

        if (!data.FLV &&
            !data.CarneAvePeixe &&
            !data.DHP &&
            !data.OutrosProdutos &&
            !data.Lanche) {

            if (!!ofertas?.length) {
                const offersFieldIsFilled = checkOffers(data);

                if (offersFieldIsFilled) {
                    if (isPedidoSwitchedToRecorrencia) {
                        possuiErros = true;

                        AlertService.show(
                            "Você deve inserir ao menos um item de uma outra categoria de produtos.",
                            'Atenção',
                            'warning'
                        );

                        return possuiErros;
                    } else {
                        return possuiErros = false;
                    }
                } else {
                    if (isPedidoSwitchedToRecorrencia) {
                        possuiErros = true;
                    }
                }
            }

            // NENHUM CAMPO PREENCHIDO E SEM OFERTAS RETORNA ERRO NOS 2 TIPOS DE PEDIDO
            methods.setError('FLV', {
                shouldFocus: true
            })

            AlertService.show('Você deve escolher ao menos um item para fazer o seu pedido.');

            possuiErros = true;

            return possuiErros;
        };

        const isEntregaExpressa = data.TipoEntrega === Genericos.ENTREGA_EXPRESSA;

        // função podeRealizar não será disparada para pedidos convertidos
        if (!podeRealizar(
            data.DataEntrega,
            Number(data.CodHorario),
            pedidoState.data.LojaId,
            entregaOuRetirada,
            isEntregaExpressa,
            isPedidoSwitchedToRecorrencia
        )) {

            AlertService.show('Data/Hora de pedido indisponível.');

            methods.setError('DataEntrega', {
                shouldFocus: true
            })

            possuiErros = true;

            return possuiErros;
        }

        if (deliveryDateNotProvided(data.DataEntrega)) {
            possuiErros = true;
        }

        return possuiErros;
    }

    const resetLocalStorage = () => {
        localStorage.removeItem('FLV');
        localStorage.removeItem('CarneAvePeixe');
        localStorage.removeItem('DHP');
        localStorage.removeItem('OutrosProdutos');
        localStorage.removeItem('Lanche');
        localStorage.removeItem('OfertaPersonalizada');
        localStorage.removeItem('Substituicao');
    };

    const setSacolaESubstituicao = (request: any) => {
        request.IncluirSacola = request.IncluirSacola === '1';

        switch (request.Substituicao) {
            case "1":
                request.Substituicao = false
                break;
            case "2":
                request.Substituicao = true
                request.SubstituicaoItemAItem = 2;
                break;
            case "3":
                request.Substituicao = true;
                request.SubstituicaoItemAItem = 3;
        };
    }

    const salvarTermoSeNecessario = async (request: any) => {
        const isTermoSaldo = await LGPDService.salvarTermoSeNecessario(request.Cpf);

        if (!isTermoSaldo) {
            AlertService.error('Erro ao concordar com os termos de uso. Tente novamente mais tarde.');
            return;
        }
    }

    const runEssentials = (request: any) => {
        salvarTermoSeNecessario(request);
        setSacolaESubstituicao(request);

        if (horariosDisponiveisState.data.substituicaoLoja.lojaFechada) {
            request.LojaId = horariosDisponiveisState.data.substituicaoLoja.cod_siac_substituta;
            request.ObservacaoPedido = "Loja Origem: " + lojaEscolhidaState.data.nome + ' ' + request.ObservacaoPedido;
        }
    }

    const transformRecorrenciaRequest = (request: any) => {
        request.CodMeioDeContato = 1;
        request.DataProximaEntrega = request.DataEntrega;
        request.DataInicio = request.DataEntrega;
        request.TipoEntrega = 2;
        request.CodHorario = Number(request.CodHorario);
        request.numCpfCnpj = request.Cpf;
        request.RecorrenciaDia = frequency[frequency.length - 1].diaRecorrencia
    }

    const onSubmitSuccess = async (data: any) => {
        const errors = validacoesAdicionaisForm(data);

        if (errors) {
            return false;
        }

        setIsSubmitting(true);

        if (isPedidoSwitchedToRecorrencia) {
            let request = new RecorrenciaRequest(Object.assign(pedidoState.data, data))

            runEssentials(request);

            transformRecorrenciaRequest(request);

            const recorrenciaResponse: any = await RecorrenciaService.salvar(request);
            const codRecorrencia = recorrenciaResponse?.data.CodRecorrencia;

            setIsSubmitting(false);

            if (recorrenciaResponse.error) {
                AlertService.error(recorrenciaResponse.error);
            } else {
                onPurchase({
                    request: request,
                    pedidoId: codRecorrencia
                });

                localStorage.setItem(
                    "RecorrenciaAtual",
                    codRecorrencia
                );

                const title =
                    "A Loja " + lojaEscolhidaState.data.nome + " agradece seu pedido!";
                const msg = "Anote o número para acompanhar a entrega: " + codRecorrencia;

                AlertService.success(msg, title).then((res) => {
                    resetLocalStorage();

                    history.push("/pedido-recorrencia-enviado");

                    const pedidoEnviado = {
                        success: true,
                        titulo: "Pedido Confirmado",
                        primeiroTxt: `Tudo certo! Você receberá seu pedido no dia ${moment(
                            request.DataProximaEntrega,
                            "YYYY-MM-DD"
                        ).format("DD/MM/YYYY")} entre ${request.HoraEntrega} no endereço ${request.Endereco
                            }, ${request.Complemento}, ${request.Numero}.`,
                        primeiroBtnTxt: "Voltar para o início",
                        primeiroBtnLink: `/pre-atendimento/tipo-pedido/${pedidoState.data.LojaId}`,
                    };

                    dispatch(userLogout());
                    dispatch(PedidoEnviadoRecorrenciaUpdate(pedidoEnviado));
                });
            }
        } else {
            let request = new PedidoRequest(Object.assign(pedidoState.data, data))

            runEssentials(request);

            if (request.TipoEntrega === Genericos.RETIRADA_ARMARIO) {
                request.RetiradaArmario = true;
            }

            const pedidoResponse: PedidoResponse = await PedidoService.salvar(request);

            setIsSubmitting(false);

            if (!pedidoResponse.status) {
                AlertService.error(pedidoResponse.error);
            } else {
                onPurchase({
                    request: request,
                    pedidoId: pedidoResponse.codPedido
                });

                const title = "A Loja " + lojaEscolhidaState.data.nome + " agradece seu pedido!";
                const msg = "Anote o número para acompanhar a entrega: " + pedidoResponse.codPedido;
                AlertService.success(msg, title).then((res: any) => {
                    if (res.isConfirmed) {
                        resetLocalStorage();
                        history.push('/pedido-enviado');

                        const ambiente = ambienteState.data;

                        const pedidoEnviado: PedidoEnviadoInterface = {
                            nomeLoja: lojaEscolhidaState.data.nome,
                            nrPedido: pedidoResponse.codPedido,
                            numeroWhatsapp: ParametroService.getNumeroWhatsapp(parametrosLoja),
                            ambiente: ambiente,
                        };

                        //dispatch(userLogout());
                        dispatch(pedidoEnviadoUpdate(pedidoEnviado));
                        dispatch(setAmbiente(ambiente));
                    };
                });
            };
        };
    };

    const onSubmitError = async (data: any) => {
        setTimeout(() => {
            AlertService.show('Existem campos acima que não foram preenchidos corretamente.');
        }, 300);
        return data;
    }

    const didFetchStoreParams: boolean = !!parametrosLoja.length;


    const fixedNavigatorItems = [
        {
            target: "como",
            avulso: true,
            label: "Início",
            icon: IconInfo,
            iconWhite: IconInfoWhite,
            firstBlock: true,
            separator: true,
        },
        {
            target: "frutas",
            avulso: true,
            label: "Frutas, legumes e verduras",
            icon: IconApple,
            iconWhite: IconAppleWhite
        },
        {
            target: "carnes",
            avulso: true,
            label: "Carnes, aves e peixes",
            icon: IconMeat,
            iconWhite: IconMeatWhite
        },
        {
            target: "outros",
            avulso: true,
            label: "Outros produtos",
            icon: IconGlass,
            iconWhite: IconGlassWhite
        },
        {
            target: "padaria",
            avulso: true,
            label: "Padaria",
            icon: IconBread,
            iconWhite: IconBreadWhite
        },
        {
            target: "confira",
            avulso: true,
            label: "Confira também",
            icon: IconTag,
            iconWhite: IconTagWhite,
            separator: true,
        },
        {
            target: "preferencias",
            avulso: true,
            label: "Preferências",
            icon: IconAsterisk,
            iconWhite: IconAsteriskWhite,
        },
        {
            target: "pagamento",
            avulso: true,
            label: "Pagamento",
            icon: IconPayment,
            iconWhite: IconPaymentWhite
        },
        {
            target: "dados",
            avulso: true,
            label: "Dados pessoais",
            icon: IconUser,
            iconWhite: IconUserWhite,
        },
        {
            target: "entrega",
            avulso: true,
            label: "Entrega",
            icon: IconMarker,
            iconWhite: IconMarkerWhite
        },
        {
            target: "data",
            avulso: true,
            label: "Agendamento",
            icon: IconCalendar,
            iconWhite: IconCalendarWhite,
        },
    ];

    return (
        <>
            <Container>
                <Row xs="1" lg="2" style={{ marginTop: 30 }}>
                    <Col lg="3" className="hiddenXs">
                        <FixedNavigator items={fixedNavigatorItems} />
                    </Col>
                    <Col lg="9">
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(onSubmitSuccess, onSubmitError)}>
                                {
                                    isSubmitting && (
                                        // @ts-ignore
                                        <AnimationTransition renderPhrases={false} />
                                    )
                                }
                                <VitrineCarousel images={arrayImages} topBanner />
                                <p className="tituloBasicoAvulso mb4" id="como">INFORMAÇÕES</p>
                                <ProximaEntregaDisponivel listaHorarios={listaHorarios} />
                                <InformacoesImportantes parametrosLoja={parametrosLoja} />
                                <fieldset>
                                    <p className="tituloBasicoAvulso mb4">LISTA DE COMPRAS</p>
                                    <p className="textAvulso mb4">Escreva nos campos abaixo os produtos que deseja.</p>

                                    <TextAreaField
                                        image={didFetchStoreParams && arrayImagesFLV ? arrayImagesFLV : null}
                                        label="Frutas, legumes e verduras"
                                        name="FLV"
                                        id="frutas"
                                        register={methods.register}
                                        placeholder="Ex: 1 kg de banana prata orgânica madura"
                                    />

                                    <TextAreaField
                                        image={didFetchStoreParams && arrayImagesOutrosProdutos ? arrayImagesProteinas : null}
                                        label="Carnes, Aves e Peixes"
                                        name="CarneAvePeixe"
                                        id="carnes"
                                        register={methods.register}
                                        placeholder="Ex: 1 kg filé de tilápia"
                                    />

                                    <TextAreaField
                                        image={didFetchStoreParams && arrayImagesOutrosProdutos ? arrayImagesOutrosProdutos : null}
                                        id="outros"
                                        label="Outros Produtos"
                                        name="OutrosProdutos"
                                        register={methods.register}
                                        placeholder="Ex:  1 caixa de leite desnatado marca X"
                                    />

                                    <TextAreaField
                                        image={didFetchStoreParams && arrayImagesPadaria ? arrayImagesPadaria : null}
                                        label="Padaria"
                                        name="Lanche"
                                        id="padaria"
                                        register={methods.register}
                                        placeholder="Ex: 5 pães francês"
                                    />

                                    <TextAreaField
                                        image={didFetchStoreParams && arrayImagesConfira ? arrayImagesConfira : null}
                                        label="Confira Também"
                                        name="DHP"
                                        id="confira"
                                        register={methods.register}
                                        placeholder="Ex: &#13;&#10;1un de Lava Roupas OMO 800 gr.&#13;&#10;3un de Lava Roupas Liq. Sem Fragância/Menta ou Erva Doce Biowash 650ml"
                                    />

                                    {
                                        !!ofertas?.length && (
                                            <OfertasPersonalizadas
                                                offers={ofertas}
                                                register={methods.register}
                                            />
                                        )
                                    }

                                    <div className="mb-4">
                                        <p className="tituloBasicoAvulso mb4" id="preferencias">
                                            Preferências
                                        </p>
                                        <div className="textAvulso mb1">
                                            <b>Gostaria de fazer alguma orientação aos nossos separadores?</b>
                                            <div className="textAvulso mb-3">
                                                Use este espaço para informar para a loja exatamente como você gostaria
                                                de receber os itens do carrinho ou qualquer observação adicional.
                                            </div>
                                        </div>
                                        <Input placeholder="Gostaria que..." name="PreferenciasCliente" innerRef={methods.register} />
                                    </div>

                                    {didFetchStoreParams && ParametroService.getValorSacola(parametrosLoja)
                                        && <InputRadioStandardContainer
                                            name="IncluirSacola"
                                            param={ParametroService.getValorSacola(parametrosLoja)}
                                        />
                                    }

                                    {didFetchStoreParams && ParametroService.getAceitaSubstituicaoProduto(parametrosLoja)
                                        && <InputRadioSubstituicaoContainer />
                                    }
                                    <FormaPagamento
                                        didFetchParams={didFetchStoreParams}
                                        aceitaDinheiro={didFetchStoreParams ? ParametroService.getAceitaPagamentoDinheiro(parametrosLoja) : undefined}
                                        aceitaViaLink={didFetchStoreParams ? ParametroService.getAceitaPagamentoViaLink(parametrosLoja) : undefined}
                                        imagesCard={didFetchStoreParams && arrayImagesCard ? arrayImagesCard : null}>
                                    </FormaPagamento>
                                </fieldset>
                                <fieldset>
                                    <Cupom leveNatural={clienteLeveNatural} />
                                </fieldset>
                                <fieldset>
                                    <p className="tituloBasicoAvulso mb4" id="dados">Dados Pessoais</p>
                                    <div className="textAvulso mb3">
                                        <b>Deixe sempre o número do seu celular atualizado. As atualizações do pedido serão enviadas no Whatsapp.</b>
                                    </div>
                                    <DadosPessoais leveNatural={clienteLeveNatural} />
                                </fieldset>

                                {!clienteLeveNatural.ativo && (
                                    <fieldset>
                                        <p className="tituloBasicoAvulso mb4">Programa de Fidelidade</p>
                                        <div className="textAvulso mb3">
                                            Para fazer parte do nosso programa de fidelidade, receber descontos em produtos, na entrega ou poder utilizar cupons de desconto, preencha os campos abaixo:
                                        </div>
                                        <ProgramaFidelidade />
                                    </fieldset>
                                )}
                                <fieldset>
                                    <p className="tituloBasicoAvulso mb4" id="entrega">Entrega</p>
                                    <Endereco />
                                </fieldset>

                                <fieldset>
                                    <Entrega
                                        params={parametrosLoja}
                                        aceitaRecorrencia={hasRecorrencia}
                                        aceitaRetiradaEmLoja={ParametroService.getAceitaRetiradaEmLoja(parametrosLoja)}
                                        aceitaEntregaExpressa={ParametroService.getAceitaEntregaExpressa(parametrosLoja)}
                                        aceitaRetiradaEmArmario={ParametroService.getAceitaRetiradaEmArmario(parametrosLoja)}
                                    />
                                </fieldset>
                                <Button
                                    className="d-block mx-auto mb6"
                                    color="primary"
                                    disable={!didFetchStoreParams}
                                >
                                    Concluir Pedido
                                </Button>
                            </Form>
                        </FormProvider>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};


export default PedidoComponent;
