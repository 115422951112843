import OfertasPersonalizadasProvider from './OfertasPersonalizadasContext';
import TagManagerProvider from './TagManager/TagManagerContext';


export const ContextProvider = ({ children }) => {
    return (
        <TagManagerProvider>
            <OfertasPersonalizadasProvider>
                {children}
            </OfertasPersonalizadasProvider>
        </TagManagerProvider>

    )
}