import Parametro from "./Parametro";

class Loja{
    cod_siac: string;
    cod_sap: string;
    nome: string;
    CNPJ: string;
    inscricao_estadual: string;
    endereco_cep: string;
    endereco_logradouro: string;
    endereco_numero: string;
    endereco_complemento: string;
    endereco_bairro: string;
    endereco_cidade: string;
    endereco_estado: string;
    raio_atendimento_m: number;
    qtd_maxima_pedido: number;
    url_pedido: string;
    url_nps: string;
    horaEstendida: string;
    parametros: Parametro[] = [];

    constructor(init?:Partial<Loja>){
        Object.assign(this, init);
    }
}

export default Loja;