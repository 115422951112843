import styled, { css } from 'styled-components';


export const Container = styled.div`
    width: 100%;
    height: auto;
    overflow-x: hidden;
    position: relative;
    margin-bottom: 16px;
    
    header {
        width: 100%;
        height: 50px;
        padding: 16px 16px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        div:nth-child(1) {            
            span {
                display: block;
            }
            span:nth-child(2) {
                color: #24A076;
                font-weight: 500;
            }
        }
        div:nth-child(2) {
            display: flex;
            align-items: center;
        };
    };
`;

export const Warning = styled.footer`
    width: 100%;
    height: auto;
    font-size: 16px;
    font-weight: bold;
    color: #54412F;
    margin-top: 16px;
`

export const SlideButton = styled.button`
    position: absolute;
    //top: 52.24%;
    top: 47.24%;
    
    width: 32px;
    height: 32px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ right }) => right ? css`
        right: 1%`
        :
        css`
        left: 1%;
        transform: rotate(180deg);
        `
    };
    svg {
        color: #54BE95;
        font-weight: bold;
    };
    @media(max-width:599px) {
        display: none;
    }
`

export const Control = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #E0E0E0;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ index, control }) => index === control ? '#00B262' : '#E0E0E0'};
    & + div {
        margin-left: 4px;
    };
    cursor: pointer;
`;