import environment from "config/environment";
import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import "./styles.scss";

const SubHeader = () => {
  const isHomologacao = environment.title === "Homologação";
  const { clienteState } = useSelector((state: any) => state);
  const { lojaEscolhidaState } = useSelector((state: any) => state);

  const primeiroNome = function (nome: string = ""): string {
    if (nome.indexOf(" ") !== -1) {
      return nome.split(" ")[0];
    } else {
      return nome;
    }
  };

  return (
    <div className="sub-header py-1 px-3" style={{ fontSize: "80%" }}>
      <Switch>
        <Route path="/pedido">
          {clienteState.data.Nome ? (
            <b>
              {primeiroNome(clienteState.data.Nome)}, bem-vindo(a) a Loja{" "}
              {lojaEscolhidaState.data.nome}!
            </b>
          ) : (
            <b>Bem-vindo(a) a Loja {lojaEscolhidaState.data.nome}!</b>
          )}
        </Route>
        <Route path="*">
          {isHomologacao && (
            <span style={{ color: "blue" }}>[Homologação] </span>
          )}
          <b>Bem-vindo ao mundo das cores e sabores!</b> 🌿🌱
        </Route>
      </Switch>
    </div>
  );
};
export default SubHeader;
