import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navbar, NavbarBrand } from 'reactstrap';
import SubHeader from './sub-header';
import logoimg from '../../../assets/images/logo-natural.svg'

const Header = () => {

    const [loginUrl, setLoginUrl] = useState('');
    const { ambienteState } = useSelector((state: any) => state);

    useEffect(() => {
        if (ambienteState.data === 'normal') {
            setLoginUrl('/login');
        } else {
            setLoginUrl('/login-' + ambienteState.data);
        }
    }, [ambienteState])

    const isHortifruti = window.location.hostname === 'wpp.hortifruti.com.br';

    return (
        <>
            <Navbar color="primary" light expand="md">
                <NavbarBrand href={loginUrl}>
                    <div className="logo-wrapper" >
                        {isHortifruti && <img src="/assets/images/logo-hortifruti.png" alt="Logo Hortifruti" />}
                        {!isHortifruti && <img src={logoimg} alt="Logo Natural da Terra" />}
                    </div>
                </NavbarBrand>
            </Navbar>
            <SubHeader />
        </>
    )
}

export default Header;