import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ContactsState {
  data: any[];
}

const initialState = { data: [] } as ContactsState;

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setContacts(state, action: PayloadAction<any[]>) {
      state.data = action.payload;
    },
  },
});

export const { setContacts } = contactsSlice.actions;

export default contactsSlice.reducer;
