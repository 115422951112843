import styled, { css } from 'styled-components';


export const Container = styled.div`
    border: 1px solid #e0e0e0;
    display: block;
    width: 100%;
    max-width: 499px;
    height: 286px;

    border-radius:  0px 0px 4px 4px;

    padding: 16px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: -16px;

    display: flex;
    flex-direction: column;

    font-family: OpenSans;

    @media (max-width: 280px) {
        padding: 8px;
    }

    & header {
        height: 24px;
        margin-bottom: 8px;

        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        color: #54412f;

        & span {
            font-size: 16px;

            @media (max-width: 280px) {
                font-size: 12px;
            }       
        }

        & div {
            & svg {
                font-size: 24px;
                cursor: pointer;

                @media (max-width: 499px) {
                    font-size: 20px;
                }
            }

            & svg:first-child {
                margin-right: 32px;

                @media (max-width: 280px) {
                    margin-right: 20px;
                }
            }
        }
    }

`;

export const Column = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;

    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: OpenSans;

    cursor: pointer;

    ${({ selected, current }) => (selected && current) && css`
        background-color: #eeeeee;
    `}

    ${({ selected, current }) => (selected && current) && css`
        &:hover {
            background-color: #eeeeee;
        }    
    `}

    display: flex;
    justify-content: center;

    color: ${({ color, current }) => {
        return (color && current) ? color : current ? '#000000' : '#e0e0e0';
    }};

    ${({ current, color }) => {
        if (color && current) {
            return css`
                font-weight: 700;
            `
        }
    }}
`

export const HeaderColumn = styled(Column)`
    color: #54412f;
`

export const Line = styled.div`
    //border: 1px solid red;
    width: 100%;
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 14px;

    font-family: OpenSans;

    ${props => props.weekDaysHeader && css`
        font-weight: 700;
        color: #54412f;
        font-size: 15px;
    `}

    @media (max-width: 280px) {
        font-size: 12px;
    }
`;

export const Footer = styled.footer`
    width: 100%;
    display: flex;
    margin: -16px 16px 0px 16px;
    font-family: OpenSans;

    & span {
        font-size: 12px;
        color: #54412f;

        display: flex;
        align-items: center;

        @media (max-width: 280px) {
            font-size: 10px;
        }

        & + span {
            margin-left: 16px;
        }
    }
`;

export const Square = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 2px;

    background-color: ${props => props.color};

    margin-right: 4px;
`;