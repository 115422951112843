import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { createContext } from 'use-context-selector';
import TagManager from "react-gtm-module";
import { sha256 } from 'js-sha256';


export const TagManagerContext = createContext();

const orderTypes = { // tu esqueceu? rs
    "1": "teste",
    "2": "teste2",
    "3": "teste3"
}

export default function TagManagerProvider({ children }) {
    const { clienteState: { data }, pedidoState: { data: pedido } } = useSelector(state => state);

    const encryptedId = useRef();
    const encryptedEmail = useRef();

    const encryptUserData = useCallback(() => {
        if (!encryptedId.current || !encryptedEmail.current) {
            const preEncryptionId = data.googleUID ? data.googleUID[0] : data.Cpf ? data.Cpf : data.Telefone;

            encryptedId.current = sha256(preEncryptionId);

            const preEncryptionEmail = data.Email ? data.Email : undefined;

            encryptedEmail.current = preEncryptionEmail ? sha256(preEncryptionEmail) : " ";

        };
    }, [data.Cpf, data.Email, data.Telefone, data.googleUID]);

    const onVirtualPageView = useCallback(({ login = false, page, etapa }) => {
        const baseTag = {
            event: "virtualPageView",
            page: page,
            etapa: etapa,
            dominio: sessionStorage.getItem('@dominio')
        };

        TagManager.dataLayer({
            dataLayer: login ? baseTag : Object.assign(baseTag, {
                shopper: {
                    id: encryptedId.current,
                    cep: pedido.Cep || "indisponível",
                    loja: pedido.LojaId || "indisponível",
                    email: encryptedEmail.current || "indisponível",
                    tipoLogin: sessionStorage.getItem('TipoLogin')
                },
            }),
        });
    }, [pedido.Cep, pedido.LojaId]);

    const onInputChange = useCallback(({ name, value, type }) => {
        TagManager.dataLayer({
            dataLayer: {
                event: "inputItem",
                dominio: sessionStorage.getItem('@dominio'),
                shopper: {
                    id: encryptedId.current,
                    cep: pedido.Cep || "indisponível",
                    loja: pedido.LojaId || "indisponível",
                    email: encryptedEmail.current || "indisponível",
                    tipoLogin: sessionStorage.getItem('TipoLogin')
                },
                cartDetail: {
                    itens: [
                        {
                            inputText: name,
                            value: value.split('\n').join(', '),
                            type: type
                        }
                    ],
                },
            },
        });
    }, [pedido.Cep, pedido.LojaId]);

    const onInitiateOrder = useCallback(({ orderType }) => {
        TagManager.dataLayer({
            dataLayer: {
                event: "initiateOrder",
                dominio: sessionStorage.getItem('@dominio'),
                shopper: {
                    id: encryptedId.current,
                    cep: pedido.Cep || "indisponível",
                    loja: pedido.LojaId || "indisponível",
                    email: encryptedEmail.current || "indisponível"
                },
                orderType: orderType
            }
        })
    }, [pedido.Cep, pedido.LojaId]);

    const onShipping = useCallback(({ fieldValues, getDescHorario, getCupom }) => {
        TagManager.dataLayer({
            dataLayer: {
                event: "shippingOptions",
                dominio: window.location.href.split('/ped')[0],
                shopper: {
                    id: encryptedId.current,
                    cep: pedido.Cep,
                    loja: pedido.LojaId,
                    email: encryptedEmail.current
                },
                cartDetail: {
                    itens: [
                        { inputText: "flv", value: fieldValues.FLV },
                        { inputText: "proteina", value: fieldValues.CarneAvePeixe },
                        { inputText: "Confira Também", value: fieldValues.DHP },
                        { inputText: "outros", value: fieldValues.OutrosProdutos },
                        { inputText: "padaria", value: fieldValues.Lanche },
                        { inputText: "oferta personalizada", value: fieldValues.OfertaPersonalizada }
                    ],
                    aceitaSacola: fieldValues.IncluirSacola,
                    aceitaSubstituto: fieldValues.Substituicao,
                    formaPagamento: fieldValues.formaPagamento,
                    shippingOptions: {
                        tipo: orderTypes[fieldValues.TipoEntrega],

                        data: fieldValues.DataEntrega,
                        turno: getDescHorario,
                        tipoLogin: sessionStorage.getItem('TipoLogin'),
                        cupom: fieldValues.Cupom
                    },
                }
            }
        });
    }, [pedido.Cep, pedido.LojaId]);

    const onPurchase = ({ request, pedidoId, orderType }) => {
        TagManager.dataLayer({
            dataLayer: {
                event: "purchase",
                dominio: sessionStorage.getItem('@dominio'),
                shopper: {
                    id: encryptedId.current,
                    cep: request.Cep,
                    loja: request.LojaId,
                    email: encryptedEmail.current,
                },
                purchase: {
                    id: pedidoId,
                    affiliation: "Whats App",
                    cartDetail: {
                        orderType: orderType,
                        itens: [
                            { inputText: "FLV", value: request.FLV },
                            { inputText: "CarneAvePeixe", value: request.CarneAvePeixe },
                            { inputText: "DHP", value: request.DHP },
                            { inputText: "OutrosProdutos", value: request.OutrosProdutos },
                            { inputText: "Padaria", value: request.Lanche }
                        ],
                        aceitaSacola: request.IncluirSacola === "0" ? "Não" : "Sim",
                        aceitaSubstituto: request.Substituicao === "0" ? "Não" : "Sim",
                        formaPagamento: request.formaPagamento,
                        shippingOptions: {
                            tipo: request.TipoEntrega,
                            data: request.DataEntrega,
                            turno: request.DescHorario,
                            tipoLogin: sessionStorage.getItem('TipoLogin')
                        },
                    },
                },
            }
        }, []);
    };

    return (
        <TagManagerContext.Provider value={{
            onVirtualPageView,
            onInputChange,
            onInitiateOrder,
            onPurchase,
            onShipping,
            encryptUserData,
            encryptedId,
            encryptedEmail
        }}>
            {children}
        </TagManagerContext.Provider>
    )
}

