import * as ACTIONS from '../actions/actionTypes';

const initialState = {
    data: {}
}

export const horariosDisponiveisRecorrenciaReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_HORARIOS_RECORRENCIA:
            return {
                data: action.data
            };
        default:
            return state;
    }
}