import ReactDOM from "react-dom";
import App from "./App";

import { Provider } from "react-redux";
import { ContextProvider } from "context";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import "./styles/custom-bootstrap4.scss";


const theme = createTheme({
  palette: {
    primary: {
      main: "#54412F",
    },
    secondary: {
      main: "#54412F",
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <PersistGate loading={null} persistor={persistor}>
        <ContextProvider>
          <App />
        </ContextProvider>
      </PersistGate>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
