class TermoLGPD{
    Id: number;
    Cpf: string;
    Termo: string;
    DataAceite: Date;

    constructor(init?:Partial<TermoLGPD>){
        Object.assign(this, init);
    }
}

export default TermoLGPD;