import React, { createContext, useContext, useEffect, useState } from 'react';
import RecorrenciaService from 'services/RecorrenciaService';


export const OfertasPersonalizadasContext = createContext();

const OfertasPersonalizadasProvider = ({ children }) => {

    const [ofertas, setOfertas] = useState([]);
    
    const requestOfertasPersonalizadas = ({ cpf, id }) => {
        if (cpf && id) {
            RecorrenciaService.carregarOfertasPersonalizadas(cpf, id)
            .then(res => setOfertas(res))
            .catch(e => console.error('error: ', e));
        }
    };

    const requestAsyncOfertasPersonalizadas = async ({ cpf, id }) => {
        if (cpf && id) {
            const res = await RecorrenciaService.carregarOfertasPersonalizadas(cpf, id);
            setOfertas(res);
        };
    };


    return (
        <OfertasPersonalizadasContext.Provider value={{
            ofertas,
            requestOfertasPersonalizadas,
            requestAsyncOfertasPersonalizadas
        }} >
            {children}
        </OfertasPersonalizadasContext.Provider>
    )
};


export const useOfertasPersonalizadas = () => {
    const context = useContext(OfertasPersonalizadasContext);

    if (!context) {
        throw new Error('useOfertasPersonalizadas must be used within a OfertasPersonalizadasProvider');
    }

    return context;
}

export default OfertasPersonalizadasProvider;