import * as ACTIONS from '../actions/actionTypes';

const initialState = {
    data: 'normal'
};

export const ambienteReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_AMBIENTE:
            return {
                ...state,
                data: action.data
            };
        default:
            return state;
    }
}