import * as ACTIONS from '../actions/actionTypes';

const initialState = {
    data: {}
}

export const pedidoReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.PEDIDO_UPDATE:
            return {
                ...state,
                data: action.data
            };
        case ACTIONS.PEDIDO_SET_ENDERECO:
            return {
                ...state,
                data: {
                    ...state.data,
                    Cep: action.data.CEP,
                    Endereco: action.data.logradouro,
                    Numero: action.data.Numero,
                    Complemento: action.data.Complemento,
                }
            };
        case ACTIONS.PEDIDO_SET_REPETICAO:
            return {
                ...state,
                data: {
                    ...state.data,
                    Nome: action.data.Nome,
                    Cpf: action.data.Cpf,
                    Email: action.data.Email,
                    DataNascimento: action.data.DataNascimento,
                    Telefone: action.data.Telefone,
                    Cep: action.data.Cep,
                    Endereco: action.data.Endereco,
                    Numero: action.data.Numero,
                    Complemento: action.data.Complemento,
                    FLV: action.data.FLV,
                    DHP: action.data.DHP,
                    Lanche: action.data.Lanche,
                    CarneAvePeixe: action.data.CarneAvePeixe,
                    OutrosProdutos: action.data.OutrosProdutos,
                    formaPagamento: action.data.formaPagamento,
                    LojaId: action.data.LojaId,
                    Substituicao: action.data.Substituicao,
                    IncluirSacola: action.data.IncluirSacola,
                    ValorDinheiro: action.data.ValorDinheiro,
                    TipoEntrega: action.data.TipoEntrega,
                    PreferenciasCliente: action.data.PreferenciasCliente,
                    ObservacaoPedido: action.data.ObservacaoPedido,
                }
            };
        case ACTIONS.PEDIDO_SET_LOJA:
            return {
                ...state,
                data: {
                    ...state.data,
                    LojaId: action.data
                }
            };
        default:
            return state;
    }
}