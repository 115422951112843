import styled, { css } from 'styled-components';
import { Alert } from 'reactstrap';


export const Container = styled.div`    
    width: auto;
    height: auto;
    
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    &::-webkit-scrollbar { // add webkit for mozilla?
        display: none;
    } 
`;

export const Item = styled.div`
    flex: 1; // flex none valendo apenas o width?
    min-width: calc(16.66% - 13.44px);
    max-width: 218.67px;
    height: 334px;
   
    @media(max-width:1300px) {
        min-width: calc(20% - 13.44px);
    };

    ${props => props.pedidoAgendado && css`
        @media(min-width:992px) {
            min-width: calc(25% - 13.44px);
        };
    `}

    @media(max-width:1128px) {
        min-width: calc(25% - 13.44px);
    };

    @media(max-width:879px) {
        min-width: calc(33.33% - 13.44px);
    };
    
    @media(max-width:675px) {
        min-width: calc(37.5% - 13.44px);
    };

    @media(max-width:599px) {
        min-width: calc(50% - 13.44px);
    };

    @media(max-width:465px) {
        min-width: calc(70% - 13.44px);
    }; 
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    
    border-color: #E0E0E0;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    margin-right: 16px;
    
    cursor: grab;
    user-select: none;
    &:hover {
        border-color: #24A076;
    }
    > div.img-produto {
        width: 85%;
        height: 44.01%; // it was 147px
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
`;

export const Summary = styled.footer`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    color: #54412F;
    div:first-child {
        height: auto;
        p {
            font-size: 100%;
            font-weight: bold;
            text-align: center;
        } 
    }
    div:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        small {
            font-size: 16px;
            text-decoration-line: line-through;
        }
        span {
            font-size: 24px;
            font-weight: bold;
        }
    }    
`;

export const AlertBox = styled(Alert)`
    width: auto;
    position: fixed;
    bottom: 2.4%; // review
    left: 50%;
    transform: translateX(-50%);
    svg {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }
    @media(max-width:599px) {
        width: 91.11%;
    };
`;