
class DiaRecorrencia{
    Id: number;
    Nome: string;

    constructor(init?:Partial<DiaRecorrencia>){
        Object.assign(this, init);
    }
}

export default DiaRecorrencia;