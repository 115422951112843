import React from 'react';
import { useFormContext } from 'react-hook-form';

import RadioComponent from './RadioComponent';


interface InputRadioSimOuNaoComponentProps {
    name: "IncluirSacola"; // TODOS: possible fields
    param?: any;
}

// Component has specific data still (make it generic)
const InputRadioSimNao = ({ name, param }: InputRadioSimOuNaoComponentProps) => {

    const { errors } = useFormContext();

    const valueNao: string = '0';
    const valueSim: string = '1';

    return (
        <div className='mb4'>
            <div className="textAvulso requerido">
                <b>
                    Deseja sacolas para embalar suas compras?(R${param} cada)
                </b>
            </div>
            <div
                aria-invalid={errors[name] ? 'true' : 'false'}
                className='radioContainer'
            >
                <RadioComponent value={valueSim} name={name} text="Sim" />
                <RadioComponent value={valueNao} name={name} text="Não" />
            </div>
        </div>
    );
}

export default InputRadioSimNao;