import { useContext } from 'react';
import { OfertasPersonalizadasContext } from '../context/OfertasPersonalizadasContext';


export const useOfertasPersonalizadas = () => {
    const context = useContext(OfertasPersonalizadasContext);

    if (!context) {
        throw new Error('useOfertasPersonalizadas must be used within a OfertasPersonalizadasProvider');
    }

    const { ofertas, requestOfertasPersonalizadas, requestAsyncOfertasPersonalizadas } = context;

    return { ofertas, requestOfertasPersonalizadas, requestAsyncOfertasPersonalizadas };
}