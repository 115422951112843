import * as ACTIONS from "../actions/actionTypes";

const initialState = {
  data: {
    entrega: [],
    retirada: [],
    retiradaArmario: [],
    entregaExpressa: [],
    entregaOuRetirada: "",
    substituicaoLoja: {},
  },
  loading: false,
  error: false,
};

export const horariosDisponiveisReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.REQUEST_HORARIOS_DISPONIVEIS:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.SET_HORARIOS_DISPONIVEIS_ENTREGA_OU_RETIRADA:
      return {
        ...state,
        data: {
          ...state.data,
          entregaOuRetirada: action.data,
        },
      };
    case ACTIONS.SUCCESS_HORARIOS_DISPONIVEIS:
      return {
        ...state,
        data: {
          ...state.data,
          entrega: action.data.entrega,
          retirada: action.data.retirada,
          retiradaArmario: action.data.retiradaArmario,
          entregaExpressa: action.data.entregaExpressa,
          substituicaoLoja: action.data.substituicaoLoja,
        },
        loading: false,
        error: false,
      };
    case ACTIONS.FAILURE_HORARIOS_DISPONIVEIS:
      return {
        data: initialState.data,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
