import { lazy, Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ConsultaStatusPedidos from "../components/ConsultaStatusPedidos/";
import Layout from "../components/Layout";
import PrivateRoute from "./private-route";
import RouteWrapper from "./router-wrapper";
import PedidoComponent from "../components/Pedido/index";
import FallbackComponent from "../common/FallbackComponent";
const PedidoRecorrencia = lazy(() =>
import("../Pages/Recorrencia/PedidoRecorrencia")
);
const PreAtendimento = lazy(() => import("../components/PreAtendimento/index"));
const PedidoEnviado = lazy(() => import("../components/Pedido/PedidoEnviado"));
const PedidoRecorrenciaEnviado = lazy(() =>
import("../components/PedidoRecorrenciaEnviado.js")
);

const isNaturalDaTerra =
  window.location.hostname === "wpp.naturaldaterra.com.br";
const urlQuery = window.location.search !== '' ? window.location.search : "?utm_medium=PortalHNT&utm_source=wppFav"
const urlNatural = "https://wpp.digital.naturaldaterra.com.br";
const urlHortifruti = "https://wpp.digital.hortifruti.com.br";
const WPP2Url = isNaturalDaTerra ? urlNatural : urlHortifruti;
const WPP2UrlLogin = WPP2Url + "/login";
const WPP2UrlLoginB2b = WPP2Url + "/login-b2b";
const WPP2B2bLoginQuery = WPP2UrlLogin + urlQuery;

export default function Routes() {
  return (
    <Suspense fallback={FallbackComponent}>
      <BrowserRouter>
        <Switch>
          <PrivateRoute
            exact
            path="/"
            layout={Layout}
            component={() => {
              window.location.href = WPP2B2bLoginQuery
              return null;
            }}
          />
          <RouteWrapper
            exact
            path="/login"
            layout={Layout}
            component={() => {
              window.location.href = WPP2B2bLoginQuery
              return null;
            }}
          />
          <Route
            path="/login-b2b"
            component={() => {
              window.location.href = WPP2UrlLoginB2b + urlQuery;
              return null;
            }}
          />
          <RouteWrapper
            path="/lembrete/:lembrete_id"
            layout={Layout}
            component={() => {
              const id = window.location.href.split("lembrete/")[1];
              window.location.href = `${WPP2Url}/lembrete/${id}`;
              return null;
            }}
          />
          <PrivateRoute
            path="/pre-atendimento"
            layout={Layout}
            component={PreAtendimento}
          />
          <Route
            path="/hortifruti-recorrencia"
            layout={Layout}
            component={() => {
              window.location.href = WPP2B2bLoginQuery
              return null;
            }}
          />
          <PrivateRoute
            path="/pedido"
            layout={Layout}
            component={PedidoComponent}
          />
          <PrivateRoute
            exact
            path="/pedido-enviado"
            layout={Layout}
            component={PedidoEnviado}
          />
          <PrivateRoute
            exact
            path="/pedido-recorrencia-enviado"
            layout={Layout}
            component={PedidoRecorrenciaEnviado}
          />
          <PrivateRoute
            exact
            path="/consulta-status-pedidos/:apenasConcluido?"
            layout={Layout}
            component={ConsultaStatusPedidos}
          />
          <PrivateRoute
            path="/pedido-recorrencia"
            layout={Layout}
            component={PedidoRecorrencia}
          />
          <RouteWrapper
            path="/not-found"
            layout={Layout}
            component={() => {
              window.location.href = WPP2B2bLoginQuery
              return null;
            }}
          />
          <Redirect
            to="/not-found"
            component={() => {
              window.location.href = WPP2B2bLoginQuery
              return null;
            }}
          />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}
