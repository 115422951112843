export class Parametro {
    Valor: string;
    Cod_Parametro: number;
    Condicao: number;
    CodLoja:string;

    constructor(init?:Partial<Parametro>){
        Object.assign(this, init);
    }

}

export default Parametro;