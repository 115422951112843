import React, { useState, useLayoutEffect } from 'react';

export const useWindowSize = () => {
    console.log('realizando consulta de mídia...');
    
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerWidth);
        };
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [])

    return size;
};

