import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import { IoIosClose } from 'react-icons/io';

import { Modal, Backdrop } from './styles';


const QuantityModal = (props) => {
    const { 
        isOpen, 
        closeModal, 
        productInformation, 
        setProductQuantity, 
        fillQuantity: setQuantity } = props;

    const [userAlert, setUserAlert] = useState(false);    

    const handleCloseClick = () => {
        resetAlert();
        closeModal();
    };

    const handleClick = (e) => {
        // avoid the backdrop to listen the close click above
        e.stopPropagation(); 
    };

    const resetAlert = () => {
        if (userAlert) {
            setUserAlert(false);
        };
    };
    
    const fillQuantity = () => {
        resetAlert();
        setQuantity();
    }
    
    const handleOnChange = (event) => {
        const value = event.target.value;

        if (value.length > 20) {
            setUserAlert(true);
        };

        setProductQuantity(event.target.value)
    }

    return isOpen && ReactDOM.createPortal(
        <Backdrop onClick={handleCloseClick} >
            <Modal onClick={handleClick}>
                <div onClick={handleCloseClick}>
                    <IoIosClose size={22} />
                </div>
                <header>
                    <div>
                        <div>
                            <img src={productInformation.imgUrl} alt={productInformation.description} />
                        </div>
                        <div>
                            <span>
                                {productInformation.description}
                            </span>
                            <span>
                                <b>
                                    {productInformation.finalPrice}
                                </b>
                            </span>
                        </div>
                    </div>
                </header>
                <div>
                <div style={{ height: '8px' }}>
                        {
                            userAlert && (
                                <small style={{ color: 'red' }} >
                                    Insira apenas quantidades.
                                </small>
                            )
                        }
                    </div>
                    <span>
                        Escreva uma quantidade
                    </span>
                    
                    <textarea
                    onChange={(e) => handleOnChange(e)} 
                    placeholder="Ex: 12 unidades ou 250g"  />
                    <button onClick={fillQuantity} >Confirmar</button>
                </div>
                <footer>
                    <div>
                        <span>
                            <b>Atenção!</b> Ofertas válidas enquanto durarem os estoques. <br />
                            Desconto não é válido para substituição por outro produto similar.
                        </span>
                    </div>
                </footer>
            </Modal> 
        </Backdrop>,
        document.getElementById("portal")
    );      
};

export default QuantityModal;