import toNumber from 'lodash.tonumber';
import Loja from "../models/Loja";
import Parametro from "../models/Parametro";

import apiPedido from "./apis/ApiPedido";

class LojaService {
  /**
   * Busca os parametros de uma loja
   * @param cep
   */
  static async listaParametros(codLoja: string): Promise<Parametro[]> {
    const res = await apiPedido.get("Parametro/ListaParametroLoja/" + codLoja);

    let parametros: Parametro[] = [];

    res.data.valor.map((parametro: Parametro) => {
      parametro.CodLoja = codLoja;
      parametros.push(parametro);
      return parametro;
    });
    return parametros;
  }

  /**
   * Lista todas as lojas disponiveis
   * @returns
   */
  static async listaLojas(): Promise<Loja[]> {
    const res = await apiPedido.get("Parametro/ListaLojas");

    let lojas: Loja[] = [];

    res.data.valor.map((loja: Loja) => {
      lojas.push(loja);
      return loja;
    });

    return lojas;
  }

  /**
   * Filtra uma loja por um array de lojas
   * @param lojas
   * @param codLoja
   * @returns
   */
  static obterLojaPeloCod(lojas: Loja[], codLoja: any): Loja {
    const loja = new Loja(
      lojas.filter((item: Loja) => {
        return toNumber(item.cod_siac) === toNumber(codLoja);
      })[0]
    );

    return loja;
  }
}

export default LojaService;
