import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import { PersistConfig } from 'redux-persist/es/types';

const persistConfig: PersistConfig<any> = {
    key: 'root',
    storage: storageSession,
    blacklist: ['horariosDisponiveisState']
}

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
    persistedReducer,
    composeEnhancers(
        applyMiddleware(sagaMiddleware),
    )
);

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { store, persistor };