import styled from 'styled-components';


export const Container = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;

    width: 100%;
    height: 100%;

    padding: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.5);
`;

export const Loading = styled.div`
    width: 335px;
    height: 270px; 
    max-width: 100%;

    border-radius: 4px;
    background-color: #FFF;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        
    font-family: OpenSans, sans-serif;
    font-weight: bold;
    font-size: ${props => props.renderPhrases ? "14px" : "18px"};
    color: #54412f;
    font-weight: 800;
    
    user-select: none;

    @media(max-width: 768px) {
        width: 240px;
        height: 240px;

        font-size: ${props => props.renderPhrases ? "12.25px" : "15px"};
    }

    & > div:first-child {
        height: 60%;
        width: 50%;

        @media(min-width: 768px) {
            width: 60%;
        }
    }

    & > div:last-child {
        margin-top: -25px;
        width: 100%;
        flex: 1;

        overflow: hidden;
        word-wrap: break-word;

        text-align: center;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media(max-width: 768px) {
            margin-top: -30px;
        }
        
        & div:first-child {
            height: 27%;
            width: 100%;
        }

        & span {
            flex: 1;
            padding: 8px;
            margin-top: 10px;
        }
    }
`