import toString from "lodash.tostring";
import trim from 'lodash.trim';

export class Pedido {
  Id: number;
  Nome: string;
  Cpf: string;
  Email: string;
  DataNascimento: string;
  Telefone: string;
  Cep: string;
  Endereco: string;
  Numero: string;
  Complemento: string;
  FLV: string;
  DHP: string;
  Lanche: string;
  CarneAvePeixe: string;
  OutrosProdutos: string;
  formaPagamento: string;
  numeroatendimentoHp: string;
  StatusPedido: string;
  LojaId: string;
  Substituicao: number | string | boolean;
  AtualizarCadastro: boolean;
  DataEntrega: Date;
  IncluirSacola: string | boolean;
  ValorDinheiro: string;
  ValorVenda: string;
  CodRecorrencia: number;
  CodHorario: number;
  CodDia: string;
  DiaRecorrencia: number;
  DescHorario: string;
  CodMeioDeContato: number;
  TipoEntrega: boolean | string | number;
  HoraEntrega: number;
  DataObs: string;
  PreferenciasCliente: string;
  ObservacaoPedido: string;
  Cupom: string;
  RetiradaArmario: any;
  EntregaExpressa: any;

  constructor(init?: Partial<Pedido>) {
    Object.assign(this, init);
  }

  /**
   * Retorna um boolean se o pedido está ou não concluído
   */
  public isConcluido(): boolean {
    return this.StatusPedido === "5";
  }

  /**
   * Transforma os dados para usar em um form
   * @returns
   */
  public transformToForm(onlyFields: string[] = []): any[] {
    let out: any = [];

    Object.entries(this).forEach((item: any) => {
      item[1] = trim(toString(item[1]));

      if (onlyFields.length === 0 || onlyFields.indexOf(item[0]) !== -1) {
        out.push(item);
      }
    });

    return out;
  }
}

export default Pedido;
