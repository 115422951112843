

import { put, call, delay, all } from 'redux-saga/effects';

import { successHorariosDisponiveis, failureHorariosDisponiveis, setHorariosDisponiveisEntregaOuRetirada } from '../../actions';
import Horario from '../../models/Horario';
import SubstituicaoLoja from '../../models/SubstituicaoLoja';
import RecorrenciaService from '../../services/RecorrenciaService';

export default function* asyncHorariosDisponiveis(action: any) {
    try {
        yield delay(100);

        yield put(setHorariosDisponiveisEntregaOuRetirada(action.data.entregaOuRetirada));

        const [listaHorariosEntrega, listaHorariosRetirada,
            listaHorariosRetiradaArmario, listaHorariosEntregaExpressa,
            substituicaoLoja]: [
                Horario[],
                Horario[],
                Horario[],
                Horario[],
                SubstituicaoLoja
            ] = yield all([
                call(RecorrenciaService.listaHorarioDisponivelEntregaPorData, action.data.codLoja, action.data.data, action.data.isRecorrencia), // entrega
                call(RecorrenciaService.listaHorarioDisponivelRetiradaPorData, action.data.codLoja, action.data.data), // retirada
                call(RecorrenciaService.listaHorarioDisponivelRetiradaArmarioPorData, action.data.codLoja, action.data.data), // retirada armário
                call(RecorrenciaService.listaHorarioDisponivelEntregaExpressaPorData, action.data.codLoja, action.data.data), // entrega expressa
                call(RecorrenciaService.checaSubstituicaoLoja, action.data.codLoja, action.data.data) // substituição loja
            ]);


        yield put(
            successHorariosDisponiveis(
                listaHorariosEntrega,
                listaHorariosRetirada,
                listaHorariosRetiradaArmario,
                listaHorariosEntregaExpressa,
                substituicaoLoja
            )
        )


    } catch (err) {
        yield put(failureHorariosDisponiveis());

    }

}
