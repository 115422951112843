import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import range from 'lodash.range';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Container, Column, Line, Footer, Square, HeaderColumn } from './styles';
import moment from 'moment';

const color = '#54412f';
const weekDaysHeader = [
    {
        day: 'D',
        color
    },
    {
        day: 'S',
        color
    },
    {
        day: 'T',
        color
    },
    {
        day: 'Q',
        color
    },
    {
        day: 'Q',
        color
    },
    {
        day: 'S',
        color
    },
    {
        day: 'S',
        color

    },
]

const monthNames = {
    0: 'Janeiro',
    1: 'Fevereiro',
    2: 'Março',
    3: 'Abril',
    4: 'Maio',
    5: 'Junho',
    6: 'Julho',
    7: 'Agosto',
    8: 'Setembro',
    9: 'Outubro',
    10: 'Novembro',
    11: 'Dezembro'
}


const CustomCalendar = ({ didConvertToRecorrencia, handleCloseCalendar, watchDate, watchFrequency, onChange }) => {
    const { watch, setValue } = useFormContext();

    const dataEntrega = watch(watchDate);
    const frequency = watch(watchFrequency);

    const [calendar, setCalendar] = useState([]);
    const [month, setMonth] = useState(new Date(dataEntrega).getMonth());
    const [year, setYear] = useState(new Date(dataEntrega).getFullYear());

    const handleMonth = (go) => {
        if (go === 'back') {
            if (month === 0) {
                setMonth(11);
                setYear(year => year - 1);
            } else {
                setMonth(month => month - 1);
            }
        }

        if (go === 'forward') {
            if (month === 11) {
                setMonth(0);
                setYear(year => year + 1);
            } else {
                setMonth(month => month + 1);
            }
        }
    };

    const handleDataEntrega = (value) => {
        let deliveryDate = value.date;
        if (!isDateBefore(deliveryDate) && value.current) {
            setValue(watchDate, moment(deliveryDate).format('YYYY-MM-DD'));
            onChange();
            handleCloseCalendar();
        };
    };


    useEffect(() => {
        // descubro em que dia da semana o mês começa 
        const firstWeekDayOfMonth = new Date(year, month, 1).getDay();

        //criação de variável descritiva com a quantidade de espaços que deverão ser preenchidos antes do dia primeiro 
        const quantityOfDaysBefore = firstWeekDayOfMonth;

        // descubro o último dia do mês passado e subtraio a quantidade de espaços que devo preencher
        // aqui eu descubro que dia será o primeiro dia do calendário (mês passado)
        const lastDayOfMonthBefore = new Date(year, month, 0).getDate();
        const startFillFromMonthBefore = lastDayOfMonthBefore - (quantityOfDaysBefore - 1);

        // inicio a variável que representa o calendário do mês que está sendo mostrado
        let calendarInterval = [];

        for (var index = startFillFromMonthBefore; index <= lastDayOfMonthBefore; index++) {
            let referenceDate = new Date(year, month === 0 ? 11 : month - 1, index); // alterado

            calendarInterval.push({
                day: index,
                current: false,
                date: referenceDate,
                color: setDeliveryOrRemind(referenceDate),
            })
        };

        const lastWeekDayOfMonthDate = new Date(year, month + 1, 0)
        const lastDayOfMonth = lastWeekDayOfMonthDate.getDate();
        const currentMonthRange = range(lastDayOfMonth);
        const currentMonthDays = Array.from(currentMonthRange, (v, k) => {
            let referenceDate = new Date(year, month, k + 1);

            return (
                {
                    day: k + 1,
                    current: !isDateBefore(referenceDate),
                    date: new Date(referenceDate),
                    color: setDeliveryOrRemind(referenceDate),
                }
            )
        });
        calendarInterval = [...calendarInterval, ...currentMonthDays];
        // descubro qual é o dia da semana do último dia do mês 
        // desta forma consigo saber quantos espaços devo preencher com o próximo mês
        const lastWeekDayOfMonth = lastWeekDayOfMonthDate.getDay();
        const daysToFill = 6 - lastWeekDayOfMonth;
        const startFillFromMonthNext = Array.from(range(daysToFill), (v, k) => (
            {
                day: k + 1,
                current: false,
                date: new Date(year, month + 1, k + 1),
                color: setDeliveryOrRemind(new Date(year, month + 1, k + 1))
            }));
        calendarInterval = [...calendarInterval, ...startFillFromMonthNext];
        let formattedCalendar = [];
        for (var i = 0; i < (calendarInterval.length / 7); i++) {
            const week = calendarInterval.slice(i * 7, i * 7 + 7);
            formattedCalendar.push(week);
        }
        setCalendar(formattedCalendar);

    }, [month, year, dataEntrega, frequency]);

    const isDateBefore = (referenceDate) => {
        return !!moment(referenceDate).isBefore(moment(), 'day');
    }

    const setDeliveryOrRemind = (date) => {
        const referenceDate = moment(date);
        const deliveryDate = moment(dataEntrega);
        // if (moment(referenceDate).isBefore(moment(), 'day')) {
        //     return '#e0e0e0';
        // }
        if (referenceDate.isBefore(deliveryDate)) {
            return null;
        }
        if (frequency) {
            const recorrenciaDia = Array.isArray(frequency) ? frequency[frequency.length - 1].diaRecorrencia : frequency;

            const diff = referenceDate.diff(deliveryDate, 'days');
            const diffLembrete = referenceDate.add(2, 'days').diff(deliveryDate, 'days');

            if ((diff % recorrenciaDia) === 0) {
                return "#24A076";
            } else if ((diffLembrete % recorrenciaDia) === 0) {
                return "#FAA61A";
            }
        }
        return null;
    };


    return (
        <>
            <Container className='mb4' >
                <header>
                    <span>
                        <b>
                            {monthNames[month]} de {year}
                        </b>
                    </span>
                    <div>
                        <IoIosArrowBack onClick={() => handleMonth('back')} />
                        <IoIosArrowForward onClick={() => handleMonth('forward')} />
                    </div>
                </header>
                <Line weekDaysHeader={true} >
                    {
                        weekDaysHeader.map(value => {
                            return (
                                <HeaderColumn color={value.color} >
                                    {value.day}
                                </HeaderColumn>
                            )
                        })
                    }
                </Line>
                {
                    calendar.map((week, idx) => {
                        return (
                            <Line key={idx} style={{ display: 'flex' }} >
                                {
                                    week.map((value, idx) => {
                                        return (
                                            <Column
                                                key={idx}
                                                color={value.color}
                                                current={value.current}
                                                onClick={() => handleDataEntrega(value)}
                                                selected={moment(value.date).format('YYYY-MM-DD') === dataEntrega}
                                            >
                                                {value.day}
                                            </Column>
                                        )
                                    })
                                }
                            </Line>
                        )
                    })
                }
            </Container >
            {
                didConvertToRecorrencia && (
                    <Footer className='mb4' >
                        <span>
                            <Square color={"#24A076"} />
                            <b>
                                DIA DE ENTREGA
                            </b>
                        </span>
                        <span>
                            <Square color={"#FAA61A"} />
                            <b>
                                LEMBRETE
                            </b>
                        </span>
                    </Footer>
                )
            }
        </>
    )
}

export default CustomCalendar;