import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Input, Label } from 'reactstrap';
import Horario from '../../../models/Horario';
import LoadingInline from '../../LoadingInline';
import * as Genericos from '../../../hooks/Genericos';
import { useShippingTracking } from '../../../hooks/TagManager/index';


const Turno = (props: any) => {
    const { register, errors, getValues } = useFormContext();
    const { dataEntrega, tipoEntrega, isEntregaExpressa } = props;

    const [listaHorarios, setListaHorarios] = useState<Horario[]>([]);

    const [getDescHorario, setDescHorario] = useState('');
    const [selectedField, setSelectedField] = useState(null); // adicionado

    const { horariosDisponiveisState, pedidoState } = useSelector((state: any) => state);
    const { onShipping } = useShippingTracking();

    const isPedidoParaHoje = (dataEntrega: string): boolean => {
        const isHoje: boolean = (dataEntrega === moment().format('YYYY-MM-DD'));
        return isHoje;
    }

    useEffect(() => {
        if (tipoEntrega === Genericos.RETIRADA_ARMARIO) {
            setListaHorarios(horariosDisponiveisState.data.retiradaArmario);
        } else
            if (tipoEntrega === Genericos.ENTREGA_EXPRESSA) {
                // Se for selecionado entregaExpressa, seta descHorario para a hora atual + 1.
                //setListaHorarios(horariosDisponiveisState.data.entregaExpressa);
                let horas = new Date().getHours() + 1; // hora + 1
                let minutos = (new Date().getMinutes() + 1 < 10 ? '0' : '') + (new Date().getMinutes() + 1); // minuto com zero
                setDescHorario(`Até ${horas}:${minutos}h`);
            } else {
                if (horariosDisponiveisState.data.entregaOuRetirada === 'Entrega') {
                    setListaHorarios(horariosDisponiveisState.data.entrega);
                } else {
                    setListaHorarios(horariosDisponiveisState.data.retirada);
                }
            }
    }, [horariosDisponiveisState, isEntregaExpressa, tipoEntrega]);

    const isChecked = (option: any) => { // adicionado
        return selectedField === option;
    };

    const getContainerClassName = (option: any) => { // adicionado
        if (isChecked(option)) {
            return 'radioBtnSubstituicao radioSelected';
        }

        return 'radioBtnSubstituicao';
    };

    const handleClick = (option: any) => { // adicionado
        setSelectedField(option);
    }

    const changeDescHorario = (desc: string) => {
        setDescHorario(desc);
    };

    useEffect(() => {
        if (getDescHorario.length > 0) {
            const fieldValues = getValues();
            onShipping({ fieldValues, getDescHorario });
        };
    }, [getDescHorario, getValues, onShipping, pedidoState.data.Email, pedidoState.data.LojaId])

    return (
        <>
            <div className='radioContainer mb6'>
                {horariosDisponiveisState.loading
                    ? <div>
                        <LoadingInline />
                    </div> :
                    tipoEntrega === Genericos.ENTREGA_EXPRESSA ? <>
                        <div className={getContainerClassName("6")}
                            onClick={() => handleClick("6")}>
                            <Label className="radioBtn">
                                <Input type="radio" innerRef={register({ required: true })} name="CodHorario" value={"6"} defaultChecked={true} className="mx-0" />
                                <div className=" textAvulso textRadioBtnWithContainer">Entrega em até 1h</div>
                            </Label>
                        </div>
                    </>
                        : listaHorarios.length > 0 && (!horariosDisponiveisState.data.substituicaoLoja.lojaFechada || (horariosDisponiveisState.data.substituicaoLoja.lojaFechada && horariosDisponiveisState.data.substituicaoLoja.cod_siac_substituta))
                            ?
                            <>
                                <div  >
                                    <Label className="textAvulso mb2" id="turno-label"><b>Escolha o horário</b></Label>
                                    <div aria-invalid={errors.CodHorario ? true : false}>
                                        {tipoEntrega ? <>
                                            {!isEntregaExpressa && listaHorarios.map((horario: Horario) => {
                                                return (
                                                    <div key={horario.CodHorario} className={getContainerClassName(horario.CodHorario)}
                                                        onClick={() => handleClick(horario.CodHorario)} >
                                                        <Label className="radioBtn">
                                                            <Input
                                                                type="radio"
                                                                innerRef={register({ required: true })}
                                                                name="CodHorario"
                                                                value={horario.CodHorario}
                                                                onChange={() => changeDescHorario(horario.DescHorario)}
                                                                className="mx-0"
                                                            />
                                                            <div className=" textRadioBtnWithContainer textAvulso">{horario.DescHorario}</div>
                                                        </Label>
                                                    </div>
                                                )
                                            })}
                                            {/* {isEntregaExpressa && <>
                                        <Label>
                                            <Input type="radio" innerRef={register({ required: true })} name="CodHorario" value={"6"} defaultChecked={true} className="mx-0" />
                                            <div className="ml-3">Entrega em até 1h</div>
                                        </Label>
                                    </>} */}
                                        </> : 'Selecione o tipo de entrega desejado.'}
                                    </div>

                                    <Label>
                                        <Input type="hidden" innerRef={register({ required: true })} name="DescHorario" id="DescHorario" value={getDescHorario} readOnly />
                                    </Label>
                                </div>
                                {horariosDisponiveisState.data.substituicaoLoja.lojaFechada
                                    && <div className="text-danger">Olá, infelizmente o serviço de retirada em loja não está disponível na data que você escolheu,  mas podemos levar nossos produtos até você. Escolha a opção Entrega em Domicílio que entregamos seu pedido na sua casa. Será um prazer te atender.</div>}
                            </>
                            :
                            (horariosDisponiveisState.data.substituicaoLoja.lojaFechada && !horariosDisponiveisState.data.substituicaoLoja.cod_siac_substituta
                                ? <div className="text-danger">Olá, infelizmente estaremos fechados na data que você escolheu, mas se for possível programar sua entrega para outra data ficaremos felizes em te atender.</div>
                                : <div className="text-danger">
                                    {isPedidoParaHoje(dataEntrega)
                                        ? 'Devido ao horário, só temos data futura para realizar seu pedido.'
                                        : 'Infelizmente não temos nenhum horário para entrega no dia escolhido.'
                                    }
                                </div>
                            )
                }
            </div>
        </>
    );
}

export default Turno;
