import { useFormContext } from "react-hook-form";
import { Input } from "reactstrap";

const ProgramaFidelidade = () => {
  const { register } = useFormContext();

  return (
    <div className="mb6">
      <div className="row">
        <div className="mb-4 col-lg-3">
          <p className="textAvulso mb2"><b>Data Nascimento</b></p>
          <Input name="DataNascimento" type="date" innerRef={register}></Input>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <p className="textAvulso mb2"><b>E-mail</b></p>
          <Input name="Email" placeholder="E-mail" type="email" innerRef={register}></Input>
        </div>
      </div>
    </div>
  );
};

export default ProgramaFidelidade;
