import React from 'react';
import Header from './Header';

const Layout = ({children}) => (
    <>
      <Header />
        <div className="children-container container-fluid p-0">
          {children}
        </div>
    </>
)

export default Layout;
