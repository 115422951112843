import { useEffect } from "react";
import Routes from "./routes";
import { hotjar } from "react-hotjar";
import { hotjarConfig } from "./services/hotjar/config";
import { IconButton, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { hide } from "store/slices/notification";
import Collapse from "@material-ui/core/Collapse";
import { MdClose } from "react-icons/md";
import TagManager from 'react-gtm-module'
import '@splidejs/splide/dist/css/splide.min.css';

hotjar.initialize(hotjarConfig.hjid, hotjarConfig.hjsv);

const App = () => {
  const notification = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  const handleNotificationClose = () => {
    dispatch(hide());
  };
  
  useEffect(() => { 
    const tagManagerArgs = {
      gtmId: 'GTM-WKVS43X'
    };
    
    TagManager.initialize(tagManagerArgs) 
  }, [])

  
  return (
    <> 
      <Collapse in={notification.visible}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={notification.visible}
          autoHideDuration={4000}
          onClose={handleNotificationClose}
          >
          <Alert
            color="error"
            variant="filled"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleNotificationClose}
              >
                <MdClose />
              </IconButton>
            }
          >
            {notification.content}
          </Alert>
        </Snackbar>
      </Collapse>
      <Routes />
    </>
  );
};

export default App;


