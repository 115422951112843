import Parametro from "../models/Parametro";

const getParametroById = (parametros: Parametro[], id: number): string => {
  const parametro: Parametro = new Parametro(
    parametros.filter((item: Parametro) => item.Cod_Parametro === id)[0]
  );
  return parametro.Valor;
};

class ParametroService {
  /**
   * Retorna a imagem de promoção para frutas legumes e verduras
   * @param parametros
   */
  static getImagemPromocaoFLV(parametros: Parametro[]): string {
    return getParametroById(parametros, 1);
  }

  /**
   * Retorna a imagem de promoção para carnes, aves e peixes
   * @param parametros
   */
  static getImagemPromocaoCarneAvePeixe(parametros: Parametro[]): string {
    return getParametroById(parametros, 2);
  }

  /**
   * Retorna a imagem de promoção para departamento de outros produtos
   * @param parametros
   */
  static getImagemOutrosProdutos(parametros: Parametro[]): string {
    return getParametroById(parametros, 3);
  }

  /**
   * Retorna se aceita pagamento em dinheiro
   * @param parametros
   */
  static getAceitaPagamentoDinheiro(parametros: Parametro[]): boolean {
    return getParametroById(parametros, 4) === "1";
    }

  /**
   * Retorna a imagem de promoção para departamento de higiene & limpeza
   * @param parametros
   */
  static getImagemDHP(parametros: Parametro[]): string {
    let imagemDHP = getParametroById(parametros, 7); 
    
    return imagemDHP;
  }

  /**
   * Retorna se permite recorrência
   * @param parametros
   */
  static getPermiteRecorrencia(parametros: Parametro[]): boolean {
    return getParametroById(parametros, 9) === "1";
  }

  /**
   * Retorna o tipo prazo de entrega
   * @param parametros
   */
  static getTipoPrazoEntrega(parametros: Parametro[]): string {
    return getParametroById(parametros, 10);
  }

  /**
   * Retorna informaçao se tem pedido minimo
   * @param parametros
   */
  static getPedidoMinimoInfo(parametros: Parametro[]): string {
    return getParametroById(parametros, 11);
  }

  /**
   * Retorna se pode substituir um produto
   * @param parametros
   */
  static getAceitaSubstituicaoProduto(parametros: Parametro[]): boolean {
    const p = getParametroById(parametros, 13);

    if (!p) {
      return true;
    } else {
      return p === "1";
    }
  }

  /**
   * Retorna o valor da sacola
   * @param parametros
   */
  static getValorSacola(parametros: Parametro[]): string {
    return getParametroById(parametros, 18);
  }

  /**
   * Retorna se aceita pagamento em dinheiro
   * @param parametros
   */
  static getAceitaRetiradaEmLoja(parametros: Parametro[]): boolean {
    return getParametroById(parametros, 19) === "1";
  }
  /**
   * Retorna uma mensagem com o nome dos separadores
   * @param parametros
   */
  static getNomeSeparadores(parametros: Parametro[]): string {
    return getParametroById(parametros, 21);
  }

  /**
   * Retorna uma imagem para ilustrar os separadores
   * @param parametros
   */
  static getImagemSeparadores(parametros: Parametro[]): string {
    return getParametroById(parametros, 22);
  }

  /**
   * retorna se o horario estendido está ativo para a loja
   * @param parametros
   */
  static getHorarioEstendidoAtivo(parametros: Parametro[]): boolean {
    return getParametroById(parametros, 24) === "1";
  }

  /**
   * Retorna o horário normal de funcionamento de uma loja
   * @param parametros
   */
  static getHorarioNormal(parametros: Parametro[]): string {
    return getParametroById(parametros, 25);
  }

  /**
   * Retorna o horário normal de funcionamento de uma loja
   * @param parametros
   */
  static getHorarioEstendido(parametros: Parametro[]): string {
    return getParametroById(parametros, 26);
  }

  /**
   * Retorna a imagem de marketing
   * @param parametros
   */
  static getImagemMarketing(parametros: Parametro[]): string {
    return getParametroById(parametros, 27);
  }

  /**
   * Retorna se aceita pagamento em dinheiro
   * @param parametros
   */
  static getAceitaPagamentoViaLink(parametros: Parametro[]): boolean {
    return getParametroById(parametros, 29) === "1";
  }

  /**
   * Retorna O número de whatsapp para acompanhamento dos pedidos
   * @param parametros
   */
  static getNumeroWhatsapp(parametros: Parametro[]): string {
    return getParametroById(parametros, 32);
  }

  /**
   * Retorna o código do tipo de loja (0 = Fake, 1 = Full, 2 = Dark, 3 = Leve)
   * @param parametros
   */
  static getTipoDeLoja(parametros: Parametro[]): string {
    return getParametroById(parametros, 33);
  }

  /**
   * Retorna o texto para o item Frete na área Informações Importantes
   * @param parametros
   */
  static getTxtFrete(parametros: Parametro[]): string {
    return getParametroById(parametros, 34);
  }

  /**
   * Retorna o texto para o item Horarios De Entrega na área Informações Importantes
   * @param parametros
   */
  static getTxtHorariosDeEntrega(parametros: Parametro[]): string {
    return getParametroById(parametros, 35);
  }

  /**
   * Retorna o texto para o item Formas De Pagamento na área Informações Importantes
   * @param parametros
   */
  static getTxtFormasDePagto(parametros: Parametro[]): string {
    return getParametroById(parametros, 36);
  }

  /**
   * Retorna se a loja tem Entrega Expressa
   * @param parametros
   */
  static getAceitaEntregaExpressa(parametros: Parametro[]): boolean {
    return getParametroById(parametros, 37) === "1";
  }

  /**
   * Retorna a imagem de itens da Padaria
   * @param parametros
   */
  static getImagemCards(parametros: Parametro[]): string {
    return getParametroById(parametros, 53);
  }

  /**
   * Retorna a imagem de itens da Padaria
   * @param parametros
   */
  static getImagemPadaria(parametros: Parametro[]): string {
    return getParametroById(parametros, 38);
  }

  /**
   * Retorna se a loja aceita Retirada em Armário Inteligente.
   * @param parametros
   */
  static getAceitaRetiradaEmArmario(parametros: Parametro[]): boolean {
    return getParametroById(parametros, 39) === "1";
  }

  /**
   * Uso externo. Não utilizado no WhatsApp.
   * @param parametros
   */
  static getEnvioMensagemYalo(parametros: Parametro[]): boolean {
    return getParametroById(parametros, 40) === "1";
  }

  /**
   * Retorna o texto para o item Entrega Expressa na área Informações Importantes.
   * @param parametros
   */
  static getTxtEntregaExpressa(parametros: Parametro[]): string {
    return getParametroById(parametros, 41);
  }

  /**
   * Retorna o texto para o item Frete na área Informações Importantes do Pedido Recorrente
   * @param parametros
   */
   static getTxtFreteRecorrencia(parametros: Parametro[]): string {
    return getParametroById(parametros, 54);
  }
}

export default ParametroService;
