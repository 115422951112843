import React from 'react';
import { Col, Label, Row } from 'reactstrap';

import Select from './Select';

import { Container, SubTitle, BenefitsList, GoPrimitiveSquare } from './styles';


const Frequencia = () => {

    const [isActive, setIsActive] = React.useState(false);

    const handleClick = () => {
        setIsActive(false);
    };

    return (
        <Container className="mb4" onClick={handleClick} style={{ display: 'block' }}  >
            <Label className="textAvulso mb2">
                <b>Frequência</b>
            </Label>
            <div>
                <header>
                    <SubTitle>
                        Você gostaria de repetir este pedido com frequência?
                    </SubTitle>
                    <span>
                        Enviaremos um lembrete 2 dias antes da data pré-agendada e caso não confirme, o
                        pedido será cancelado e não haverá cobrança
                    </span>
                </header>

                <Select handleMenuActions={{ isActive, setIsActive }} />

                <SubTitle>
                    Vantagens
                </SubTitle>
                <BenefitsList>
                    <Row>
                        <Col xs="12" style={{ height: 'auto' }}>
                            <div>
                                <GoPrimitiveSquare />
                            </div>
                            <span>
                                <b>Completando 3 pedidos no mês, você ganha um cupom de 10% OFF para usar no mês seguinte. Se completar 4 pedidos, ganha 20% OFF.</b>
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6" style={{ height: 'auto' }}>
                            <div>
                                <GoPrimitiveSquare />
                            </div>
                            <span>
                                Sua lista fica salva do jeito que você escreveu.
                            </span>
                        </Col>
                        <Col xs="6" style={{ height: 'auto' }}>
                            <div>
                                <GoPrimitiveSquare />
                            </div>
                            <span>
                                Nunca fica sem seus produtos do dia-a-dia.
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6" style={{ height: 'auto' }}>
                            <div>
                                <GoPrimitiveSquare />
                            </div>
                            <span>
                                Você recebe cada pedido somente após sua confirmação.
                            </span>
                        </Col>
                        <Col xs="6" style={{ height: 'auto' }}>
                            <div>
                                <GoPrimitiveSquare />
                            </div>
                            <span>
                                Você tem atendimento preferencial.
                            </span>
                        </Col>
                    </Row>
                </BenefitsList>
            </div>
        </Container>
    )
}


export default Frequencia;