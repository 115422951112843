
export const ENTREGA_DOMICILIO_NORMAL = '0';
export const RETIRADA_LOJA_NORMAL = '1';

export const ENTREGA_DOMICILIO_RECORRENCIA = '2';
export const RETIRADA_LOJA_RECORRENCIA = '3';

export const ENTREGA_EXPRESSA = '4';
export const RETIRADA_ARMARIO = '5';

