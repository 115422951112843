import React from 'react';
import { FaSpinner } from 'react-icons/fa';

import styles from './Spinning.module.css';

const LoadingInline = () => {

    return (
        <span>
            <FaSpinner className={styles.spinning} />
        </span>
    )
}

export default LoadingInline;