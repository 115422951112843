import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NotificationState {
  visible: boolean;
  content: string;
}

const initialState = { visible: false, content: "" } as NotificationState;

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    show(state, action: PayloadAction<string>) {
      state.visible = true;
      state.content = action.payload;
    },
    hide(state, action: PayloadAction<string>) {
      state.visible = false;
    },
  },
});

export const { show, hide } = notificationSlice.actions;

export default notificationSlice.reducer;
