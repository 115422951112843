import React from 'react';
import { useSelector } from 'react-redux';
import { Input, Label } from 'reactstrap';
import InputMask from 'react-input-mask';
import { useFormContext } from 'react-hook-form';


const DadosPessoais = (props: any) => {
    const { leveNatural } = props;

    const { authState } = useSelector((state: any) => state);

    const { register, errors } = useFormContext();

    return (
        // <div className="container-dadosPessoais mb6">
        <div className="mb6">
            {leveNatural.ativo
                ? <div className="mb3">🍎🍉🍓 Cliente {leveNatural.adesao.replace("LN", "Leve Natural")} 🍓🍉🍎</div>
                : null
            }

            <div className="mb3">
                <Label className="textAvulso mb2"><b>CPF</b></Label>
                <Input
                    name="Cpf"
                    placeholder="CPF"
                    innerRef={register({ required: true })}
                    invalid={errors.Cpf ? true : false} mask="999.999.999-99"
                    maskPlaceholder={null}
                    maxLength={14}
                    tag={InputMask}
                    readOnly={authState.data.Cpf}
                />
            </div>

            <div className="mb3">
                <Label className="requerido textAvulso mb2"><b>Nome</b></Label>
                <Input
                    name="Nome"
                    placeholder="Nome"
                    maxLength={60}
                    innerRef={register({ required: true })}
                    invalid={errors.Nome ? true : false}
                ></Input>
            </div>

            {/* <div className="mb-2">
                <b className="text-danger">Atenção:</b> Atualize seu número de <b>celular</b> no cadastro.<br />
                As informações do seu pedido são enviadas via WhatsApp.
            </div> */}

            <div className="mb3">
                <Label className="requerido textAvulso mb2"><b>Celular</b></Label>
                <Input name="Telefone" placeholder="Celular" innerRef={register({ required: true })} invalid={errors.Telefone ? true : false} mask="(99) 99999-9999" maskPlaceholder={null} tag={InputMask}></Input>
            </div>
        </div>
    );
}

export default DadosPessoais;