import "./styles.scss";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-spacer"></div>
    </footer>
  );
};

export default Footer;
