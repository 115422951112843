import moment from "moment";
import { useCallback, useEffect, useState, useRef, useMemo } from "react";

import Frequencia from './Frequencia';
import Turno from "./Turno";
import Ghost from './GhostComponent';

import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Input, Label } from "reactstrap";
import { requestHorariosDisponiveis } from "../../../actions";
import { RequestHorariosDisponiveisUpdateInterface } from "../../../interfaces/RequestHorariosDisponiveisUpdateInterface";
import * as Genericos from "../../../hooks/Genericos";
import { BsClockHistory } from "react-icons/bs";
import RecorrenciaService from "../../../services/RecorrenciaService";
import TurnoRecorrencia from "./TurnoRecorrencia";
import CustomCalendar from "./CustomCalendar";

import { FaRegCalendarAlt } from 'react-icons/fa';


const validateHours = (param: any, now: any) => {
  const hours = param.Valor.split("-");
  const startDate = moment(now).set({ hour: Number(hours[0]), minute: 0 });
  const endDate = moment(now).set({ hour: Number(hours[1]), minute: 0 });

  const isBetween = now.isBetween(startDate, endDate);

  return isBetween;
};

interface EntregaComponentProps {
  aceitaRetiradaEmLoja: boolean;
  aceitaEntregaExpressa: boolean;
  aceitaRetiradaEmArmario: boolean;
  aceitaRecorrencia: boolean;
  params: any;
}

const Entrega = (props: EntregaComponentProps) => {
  // ou vai vir null ou vai vir com uma string
  const [codRecorrencia, setCodRecorrencia] = useState<any>(null);
  useEffect(() => {
    const currentCodRecorrencia = sessionStorage.getItem("RecorrenciaAtual");
    if (currentCodRecorrencia) {
      localStorage.setItem("RecorrenciaAtual", currentCodRecorrencia);
      setCodRecorrencia(currentCodRecorrencia);
      sessionStorage.removeItem("RecorrenciaAtual");
    } else setCodRecorrencia(localStorage.getItem("RecorrenciaAtual"));
  }, [])


  const {
    aceitaEntregaExpressa,
    aceitaRetiradaEmArmario,
    aceitaRetiradaEmLoja,
    aceitaRecorrencia } = props;

  const { pedidoState, horariosDisponiveisState } = useSelector(
    (state: any) => state
  );

  const [selectedField, setSelectedField] = useState(null); // adicionado
  const [checkEntregaExpressa, setCheckEntregaExpressa] = useState<boolean>(false);
  const [disableDataEntrega, setDisableDataEntrega] = useState<boolean>(false);
  const [didConvertToRecorrencia, setDidConvertToRecorrencia] = useState<boolean>(false);
  const [showCalendar, setShowCalendar] = useState<boolean>(() => {
    if (didConvertToRecorrencia) {
      return true;
    } else {
      return false;
    }
  });
  const [checkRetiradaEmArmario, setCheckRetiradaEmArmario] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const { register, setValue, watch, errors, unregister } = useFormContext();

  const watchTipoEntrega = watch("TipoEntrega");
  const watchDataEntrega = watch("DataEntrega");
  const frequency = watch("frequency");

  useEffect(() => {
    if (frequency) {
      const lastChange = frequency[frequency.length - 1];

      if (lastChange.frequency) {
        registerFieldsIfConvertedToRecorrencia();
        setDidConvertToRecorrencia(true);
        //setShowCalendar(true);

      } else {
        setDidConvertToRecorrencia(false);
        unregisterFields();
      }
    }
  }, [frequency]);

  const isChecked = (option: any) => { // adicionado
    return selectedField === option;
  };

  const getContainerClassName = (option: any) => { // adicionado
    if (isChecked(option)) {
      return 'radioBtnSubstituicao radioSelected';
    }

    return 'radioBtnSubstituicao';
  };

  const handleClick = (option: any) => { // adicionado
    setSelectedField(option);
  }

  const canConvertToRecorrencia = useMemo(() => {
    const canConvertToRecorrenciaParam = props.params?.filter((p: any) => p.Cod_Parametro === 52);

    const condition = (canConvertToRecorrenciaParam?.length > 0);
    if (condition) {
      const response = canConvertToRecorrenciaParam[0].Valor === "1";

      return response;
    }

    return null;
  }, [props.params]);

  const registerFieldsIfConvertedToRecorrencia = () => {
    //register("RecorrenciaDia", { required: true });
    register("HoraEntrega", { required: true });
  };

  const unregisterFields = () => {
    //unregister("RecorrenciaDia");
    unregister("HoraEntrega");
  };



  const handleDataEntrega = useCallback(
    async (dataEntrega: string) => {

      const tipoEntrega =
        watchTipoEntrega === Genericos.ENTREGA_DOMICILIO_NORMAL
          ? "Entrega"
          : "Retirada";

      const busca: RequestHorariosDisponiveisUpdateInterface = {
        codLoja: pedidoState.data.LojaId,
        data: moment(dataEntrega).toDate(),
        entregaOuRetirada: tipoEntrega,
      };

      dispatch(requestHorariosDisponiveis(busca));
    },
    [dispatch, pedidoState, watchTipoEntrega]
  );

  useEffect(() => {
    if (frequency) {
      const lastChange = frequency[frequency.length - 1]; // 'guardar' esse valor

      if (lastChange.frequency) {
        return;
      }
    }

    setDisableDataEntrega(checkEntregaExpressa);

    // Se DataEntrega não estiver setado, seta com a data de hoje.
    if (!watchDataEntrega) {
      setValue("DataEntrega", moment().format("YYYY-MM-DD"));
    } else {
      // Se DataEntrega estiver setado e for selecionado 'Entrega Expressa', seta para data de hoje.
      if (checkEntregaExpressa) {
        setValue("DataEntrega", moment().format("YYYY-MM-DD"));
        handleDataEntrega(watchDataEntrega);
      } else {
        handleDataEntrega(watchDataEntrega);
      }
    }
  }, [watchDataEntrega, setValue, handleDataEntrega, checkEntregaExpressa, selectedField]);

  useEffect(() => {
    // Desabilita a Entrega Expressa se o cliente selecionou o checkbox e depois mudou o tipo de entrega.
    if (
      watchTipoEntrega !== Genericos.ENTREGA_EXPRESSA &&
      checkEntregaExpressa
    ) {
      setCheckEntregaExpressa(false);
    }
  }, [checkEntregaExpressa, watchTipoEntrega]);

  const handleEntregaExpressa = () => {
    setCheckEntregaExpressa(!checkEntregaExpressa);
  };

  const handleRetiradaEmArmario = () => {
    setCheckRetiradaEmArmario(!checkRetiradaEmArmario);
  };

  const workingDaysHours = props.params.find(
    (param: any) => param.Cod_Parametro === 43
  );

  const holidayHours = props.params.find(
    (param: any) => param.Cod_Parametro === 44
  );

  const [showHoursInvalid, setShowHoursInvalid] = useState({
    visible: false,
    hours: { Valor: "" },
  });

  const isEntregaExpressa = (watchTipoEntrega === Genericos.ENTREGA_EXPRESSA);

  // Validation: Check if current hour is between start and end from the store in params
  useEffect(() => {
    if (!workingDaysHours || !holidayHours) {
      return;
    }

    const cb = async () => {
      const now = moment();
      const isHoliday = await RecorrenciaService.checkIsHoliday(now);
      const isWorkingDay = !isHoliday;

      if (isHoliday) {
        const isValid = validateHours(holidayHours, now);

        setShowHoursInvalid({ visible: !isValid, hours: holidayHours });
      } else if (isWorkingDay) {
        const isValid = validateHours(workingDaysHours, now);

        setShowHoursInvalid({ visible: !isValid, hours: workingDaysHours });
      }

      if (!isEntregaExpressa) {
        setShowHoursInvalid({ visible: false, hours: { Valor: "" } });
      }
    };

    cb();
  }, [workingDaysHours, holidayHours, isEntregaExpressa]);

  const getTextFromParam = () => {
    const result = props.params.find((param: any) => param.Cod_Parametro === 46)?.Valor;

    return result;
  }

  const handleShowCalendar = () => {
    setShowCalendar(!showCalendar);
  }

  const handleCloseCalendar = () => {
    setShowCalendar(false);
  }

  const textInterfaces = {
    entregaExpressa: () => {
      let text = getTextFromParam();
      return {
        __html: text.replaceAll('\\n', '<br/>')
      };
    },
  };

  return (
    <div >
      {
        // codRecorrencia: vem do storage
        // aceitaRecorrencia: método chamado no componente pai (está repetido - página de seleção loja)
        // canConvertToRecorrencia: verifica se parâmetro 52 retorna 1 (memoized value)
        (!codRecorrencia && (aceitaRecorrencia && canConvertToRecorrencia)) && (
          // @ts-ignore
          <Frequencia />
        )
      }
      {
        !didConvertToRecorrencia && (
          <div className="mb-4" >
            <Label className="textAvulso mb2" id="data"><b>Selecione o tipo de entrega</b></Label>
            <div className='radioContainer mb4' aria-invalid={errors.TipoEntrega ? "true" : "false"}>
              {!!props.params.length ?
                <div className={getContainerClassName(Genericos.ENTREGA_DOMICILIO_NORMAL)}
                  onClick={() => handleClick(Genericos.ENTREGA_DOMICILIO_NORMAL)}>
                  <Label className="radioBtn" >
                    <Input
                      type="radio"
                      innerRef={register({ required: true })}
                      name="TipoEntrega"
                      value={Genericos.ENTREGA_DOMICILIO_NORMAL}
                      className="mx-0"
                    />
                    <div className="textRadioBtnWithContainer textAvulso">Entrega em Domicílio</div>
                  </Label>
                </div>
                : <Ghost />
              }


              {/* {watchTipoEntrega === Genericos.ENTREGA_DOMICILIO_NORMAL && <> */}
              {!!props.params.length ?
                aceitaEntregaExpressa && (
                  <>
                    <div className={getContainerClassName(Genericos.ENTREGA_EXPRESSA)}
                      onClick={() => handleClick(Genericos.ENTREGA_EXPRESSA)}>

                      <Label className="radioBtn">
                        <Input
                          type="radio"
                          innerRef={register()}
                          name="TipoEntrega"
                          value={Genericos.ENTREGA_EXPRESSA}
                          className="mx-0"
                          defaultChecked={false}
                          onChange={handleEntregaExpressa}
                        />
                        <div
                          className="textRadioBtnWithContainer textAvulso "
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          Entrega Expressa
                          <BsClockHistory style={{ marginLeft: ".35rem" }} />
                        </div>
                      </Label>

                      {getTextFromParam() && checkEntregaExpressa && (
                        <>
                          <div className="ml-3" dangerouslySetInnerHTML={textInterfaces.entregaExpressa()} />
                        </>
                      )}
                    </div>
                  </>
                )
                : <Ghost />
              }

              {!!props.params.length ?
                aceitaRetiradaEmLoja &&
                !horariosDisponiveisState.data.substituicaoLoja.cod_siac && (
                  <>
                    <div className={getContainerClassName(Genericos.RETIRADA_LOJA_NORMAL)}
                      onClick={() => handleClick(Genericos.RETIRADA_LOJA_NORMAL)}>
                      <Label className="radioBtn">
                        <Input
                          type="radio"
                          innerRef={register({ required: true })}
                          name="TipoEntrega"
                          value={Genericos.RETIRADA_LOJA_NORMAL}
                          className="mx-0"
                        />
                        <div className="textRadioBtnWithContainer textAvulso">Retirar na loja</div>
                      </Label>

                      {watchTipoEntrega === Genericos.RETIRADA_LOJA_NORMAL && (
                        <>
                          <div className="ml-3">
                            Você escolheu retirar o seu pedido em uma de nossas lojas!
                            Para melhorar a sua experiência pedimos que siga as
                            instruções abaixo:
                            <br />* Compareça na loja escolhida no horário agendado{" "}
                            <br />
                            * Ao chegar na nossa loja, envie uma mensagem para o nosso
                            whatsapp que levamos a compra no estacionamento para você.
                            Se preferir, dirija-se ao balcão localizado na entrada e
                            informe o seu pedido, os nossos atendentes levarão a sua
                            compra até você.
                            <br />
                            <br /> Obrigado por escolher a Hortifruti Natural da
                            Terra!
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )
                : <Ghost />
              }

              {!!props.params.length ?
                aceitaRetiradaEmArmario && (
                  <>
                    <div className={getContainerClassName(Genericos.RETIRADA_ARMARIO)}
                      onClick={() => handleClick(Genericos.RETIRADA_ARMARIO)}>

                      <Label className="ml-3">
                        <Input
                          type="radio"
                          innerRef={register()}
                          name="TipoEntrega"
                          value={Genericos.RETIRADA_ARMARIO}
                          className="mx-0"
                          onChange={handleRetiradaEmArmario}
                        />
                        <div className="textRadioBtnWithContainer textAvulso">
                          Retirar na loja via armário inteligente (novidade!)
                        </div>
                      </Label>

                      {watchTipoEntrega === Genericos.RETIRADA_ARMARIO && (
                        <>
                          <div className="ml-3 textAvulso">
                            Você escolheu ganhar tempo e retirar suas compras no Armário
                            Inteligente! Para uma melhor experiência, pedimos que siga
                            as instruções abaixo:
                            <br />
                            * Você receberá um QR Code pelo Whatsapp informando que seu
                            pedido já está disponível para retirada.
                            <br />
                            * Compareça no horário escolhido. Seu pedido permanecerá
                            disponível em nosso Armário por 3 horas (o tempo da janela
                            indicada no sistema).
                            <br />
                            * Ao chegar em nossa loja, vá até nosso Armário Inteligente
                            localizado próximo ao elevador.
                            <br />
                            * Siga as instruções na tela "touch screen" e retire seu
                            pedido.
                            <br />
                            <br />
                            Obrigado por escolher a Natural da Terra!
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )
                : <Ghost />
              }
            </div>
            {showHoursInvalid.visible && isEntregaExpressa && (<div className="row mb-4">
              <div className="col">
                <div className="text-danger textAvulso">
                  O horário para selecionar a opção{" "}
                  <strong>"Entrega Expressa"</strong> é das{" "}
                  {showHoursInvalid.hours.Valor.split("-")[0]}h às{" "}
                  {showHoursInvalid.hours.Valor.split("-")[1]}h.
                  <br />
                  Você pode agendar agora uma{" "}
                  <strong>"Entrega em Domicílio"</strong> e escolher o dia e horário
                  da sua preferência.
                </div>
              </div>
            </div>
            )}
          </div>
        )
      }

      <div className="row" >
        <div className="mb4" style={{ marginLeft: '16px', width: '100%', marginRight: '16px' }} >
          <Label className="textAvulso mb2">
            <b>
              Escolha o dia
            </b>
          </Label>
          <div className="input-data" >
            <FaRegCalendarAlt onClick={handleShowCalendar} />
            <Input
              name="DataEntrega"
              readOnly={checkEntregaExpressa}
              type="date"
              aria-invalid={errors.DataEntrega ? "true" : "false"}
              min={moment().format("YYYY-MM-DD")}
              innerRef={register({ required: true })}
            />
          </div>
        </div>
      </div>
      {
        showCalendar && (
          <div className="row" >
            <CustomCalendar
              watchDate={'DataEntrega'}
              onChange={() => null}
              watchFrequency={'frequency'}
              //@ts-ignore 
              handleCloseCalendar={handleCloseCalendar}
              didConvertToRecorrencia={didConvertToRecorrencia} />
          </div>
        )
      }
      {
        didConvertToRecorrencia ? (
          <TurnoRecorrencia />
        ) : (
          (!showHoursInvalid.visible || !isEntregaExpressa) && (
            <div className="mb4">
              <Turno
                tipoEntrega={watchTipoEntrega}
                isEntregaExpressa={checkEntregaExpressa}
                dataEntrega={watchDataEntrega}
              />
            </div>
          )
        )
      }
    </div>
  );
};

export default Entrega;
