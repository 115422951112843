import React, { useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import Alert from './Alert';
import QuantityModal from './QuantityModal/index';

import { useWindowSize } from '../useWindowSize';

import { Summary, Item } from './styles';


const ItemOfertaComponent = ({ description, originalPrice, finalPrice, imgUrl, pedidoAgendado }) => {
    const { setValue, getValues } = useFormContext();
    const { OfertaPersonalizada } = getValues();

    const screenWidth = useWindowSize();

    const refItem = useRef(null);

    const [triggerAlert, setTriggerAlert] = useState(false);
    const [quantityModalIsOpen, setQuantityModalIsOpen] = useState(false);
    const [quantity, setQuantity] = useState("");

    const handleClick = () => {
        setQuantityModalIsOpen(true);
    };

    const getFormattedPrice = (value) => {
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    };

    const getLimit = (context) => {
        return context === 'modal' ? undefined : 45;
    }

    const formatText = (context = 'carousel') => {
        let limit = getLimit(context);
        if (!limit || description?.length <= limit) {
            return <span>{description}</span>;
        };

        return (
            <span style={{ cursor: 'pointer' }} title={description}>
                {description?.substring(0, limit)}...
            </span>
        );
    };

    const fillQuantity = () => {
        if (quantity.trim().length === 0) {
            return;
        };

        setValue('OfertaPersonalizada', OfertaPersonalizada.concat(`${quantity} ` + description + '\n'));
        setQuantityModalIsOpen(false);
        setQuantity("");
        setTriggerAlert(true);

        setTimeout(() => {
            setTriggerAlert(false);
        }, 3000)
    }


    return (
        [
            <Item
                ref={refItem}
                onClick={handleClick}
                pedidoAgendado={pedidoAgendado}
            >
                <div className="img-produto">
                    <img src={imgUrl} alt={description} />
                </div>
                <Summary>
                    <div>
                        {formatText()}
                    </div>
                    <div>
                        <small>{getFormattedPrice(originalPrice)}</small>
                        <span>{getFormattedPrice(finalPrice)}</span>
                    </div>
                </Summary>
            </Item>,
            <QuantityModal isOpen={quantityModalIsOpen}
                closeModal={() => setQuantityModalIsOpen(false)}
                productInformation={{
                    imgUrl,
                    description: formatText('modal'),
                    finalPrice: getFormattedPrice(finalPrice)
                }}
                setProductQuantity={setQuantity}
                fillQuantity={fillQuantity}
            />,
            <Alert visible={triggerAlert} closeAlert={() => setTriggerAlert(false)} />
        ]
    )
};

export default ItemOfertaComponent;   