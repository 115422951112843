import Cupom from "../models/Cupom";
import Pedido from "../models/Pedido";
import PedidoRequest from "../models/PedidoRequest";
import PedidoResponse from "../models/PedidoResponse";
import StatusPedido from "../models/StatusPedido";
import apiExterna from "./apis/ApiExterna";
import apiPedido from "./apis/ApiPedido";


class PedidoService {

    /**
     * Lista todos os status possiveis para os pedidos
     * @returns 
     */
    static async listaStatus(): Promise<StatusPedido[]> {
        const res = await apiPedido.get('Pedidos/ListaStatus');

        let lista: StatusPedido[] = [];

        res.data.valor.map((item: StatusPedido) => {
            lista.push(item);
            return item;
        })

        return lista;
    }

    /**
     * Lista todos os pedidos vinculados a um cpf
     * @param cpf 
     * @returns 
     */
    static async listaPedidosPorCpf(cpf: string): Promise<Pedido[]> {
        const res = await apiPedido.get('Pedidos/CarregarPorCpf/' + cpf);

        let lista: Pedido[] = [];

        res.data.valor.map((item: Pedido) => {
            lista.push(new Pedido(item));
            return item;
        })

        return lista;
    }

    /**
     * Filtra uma status por um array de status
     * @param itens 
     * @param codLoja 
     * @returns 
     */
    static obterStatusPeloId(itens: StatusPedido[], id: any): StatusPedido {
        const status = new StatusPedido(itens.filter((item: StatusPedido) => String(item.Id) === String(id))[0]);

        return status;
    }

    /**
     * Salva um pedido
     * @param data 
     * @returns 
     */
    static async salvar(data: PedidoRequest): Promise<PedidoResponse> {
        const res = await apiPedido.post('Pedidos/Salvar/', data);

        const pedidoResponse = new PedidoResponse({
            status: res.data.resultado,
            codPedido: res.data.resultado ? res.data.valor : null,
            error: res.data.resultado ? null : res.data.valor,
        });

        return pedidoResponse;
    }

    /**
     * Valida cupom
     * @param cupom 
     * @returns 
     */
    static async getCupom(cupom: string): Promise<Cupom> {
        const res = await apiExterna.get('Promocao/GetByCupom?cupom=' + cupom);

        const dadosCupom = new Cupom({
            Resultado: res.data.resultado,
            Valor: res.data.valor ? res.data.valor : null
        });

        return dadosCupom;
    }

}

export default PedidoService;