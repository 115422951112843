import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useWindowSize } from './useWindowSize';

import moment from 'moment';

import Carrousel from './Carrousel/index';
import TextAreaField from 'components/TextAreaField';

import { IoIosArrowForward } from 'react-icons/io';
import { SlideButton, Container, Control } from './styles';

import { SectionTitleList } from '../../TextAreaField/styles';


const OfertasPersonalizadas = (props) => {
    const { offers, pedidoAgendado, register } = props;
    const screenWidth = useWindowSize() // rever lógica

    //const [position, dispatch] = useReducer();
    const [control, setControl] = useState(1);
    const [controlSlices, setControlSlices] = useState(1);

    const refCarrousel = useRef(null);
    const refContainer = useRef(null);

    useEffect(() => {
        if (refCarrousel.current) {
            let totalArea = refCarrousel.current.scrollWidth;
            let visibleArea = refContainer.current.offsetWidth;

            const result = Math.ceil(totalArea / visibleArea)

            setControlSlices(result);
        }
    }, [refCarrousel, offers, screenWidth])

    const handleClick = useCallback((direction) => {
        const scroll = refCarrousel.current.offsetWidth;

        if (direction === 'left') refCarrousel.current.scrollLeft += scroll;
        if (direction === 'right') refCarrousel.current.scrollLeft -= scroll;

        if (control < controlSlices && direction === 'left') {
            setControl(oldState => oldState + 1);
        };

        if (control !== 1 && direction === 'right') {
            setControl(oldState => oldState - 1);
        };
    }, [control, controlSlices]);

    const handleControl = (index) => () => {
        if (index !== control) {
            let coefficient = index - control;
            let scrollByControl = refCarrousel.current.offsetWidth * coefficient;
            refCarrousel.current.scrollLeft += scrollByControl;
            setControl(index);
        };
    };

    const showArrowLeft = useCallback(() => {
        return (control > 1) && (
            <SlideButton onClick={() => handleClick('right')} type="button">
                <IoIosArrowForward />
            </SlideButton>
        )
    }, [handleClick, control]);

    const showArrowRight = useCallback(() => {
        return (control < controlSlices) && (
            <SlideButton
                onClick={() => handleClick('left')}
                type="button"
                right
            >
                <IoIosArrowForward />
            </SlideButton>
        )
    }, [handleClick, control, controlSlices]);

    return (
        <div className='mb-4'>
            <Container ref={refContainer} >
                <SectionTitleList >
                    Ofertas personalizadas para você
                </SectionTitleList>
                <header>
                    <div>
                        <span>{offers.length} ofertas disponíveis</span>
                        <span>
                            *válidas até {moment(offers[0].effectiveEndDate).format('DD/MM/YYYY')}
                        </span>
                    </div>
                    <div>
                        {
                            refContainer && refContainer.current?.offsetWidth > 599 &&
                            Array.from({ length: controlSlices }).map((s, idx) => (
                                <Control
                                    key={idx + 1}
                                    index={idx + 1}
                                    control={control}
                                    onClick={handleControl(idx + 1)}
                                />
                            ))
                        }
                    </div>
                </header>
                <Carrousel
                    ref={refCarrousel}
                    offers={offers}
                    pedidoAgendado={pedidoAgendado}
                />
                {showArrowLeft()}
                {showArrowRight()}
            </Container>
            <TextAreaField
                name="OfertaPersonalizada"
                register={register}
                placeholder="Clique nas ofertas acima..."
                limit={2999}
                isOfertas={true}
            />
        </div>
    )
}

export default OfertasPersonalizadas;