import styled from 'styled-components';


export const Container = styled.div`
    font-family: OpenSans;
    color: #54412F;
    line-height: 21px;

    & > div {
        width: 100%;
        height: auto;
        background-color: #eeeeee;

        border-radius: 4px;

        padding: 32px;

        @media (max-width: 280px) {
            padding: 16px;
        }

        & h3 {
            color: #A35E8E;
            font-size: 16px;
        }

        & span {
            font-size: 14px;
            color: #54412F;
        }

        & section {
            margin-top: 16px;
        }
    }
`;

export const SubTitle = styled.h3`
    color: #A35E8E;
    font-size: 16px;
    font-weight: 700;
`

export const BenefitsList = styled.div`
    font-size: 14px;
    
    list-style: none;
    padding-left: 0;

    & .row {
        display: flex;
        
        line-height: 20px;

        & > div {
            height: 20px;

            display: flex;
            align-items: center;
        }
    }
`

export const GoPrimitiveSquare = styled.div`
    width: 5px;
    height: 5px;

    background-color: #A35E8E;
    border-radius: 1px;

    display: inline-block;

    margin-right: 9.5px;
    margin-left: 2.5px;

    /* align-self: flex-start; */
`;