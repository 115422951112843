import styled from 'styled-components';


export const Container = styled.div`
    margin-top: 16px;

    background-color: #FFFFFF;
    font-family: OpenSans;
    font-size: 16px;

    width: 100%;
    height: 50px;

    border-radius: 4px;
    padding: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;

    @media (max-width: 499px) {
        padding: 16px;
        font-size: 15px
    }
    
    & svg:first-child {
        margin-right: 12px;
        color: ${props => props.chosen ? "#A35E8E" : "#eeeeee"};
        font-size: 18.5px;

        @media (max-width: 280px) {
            margin-right: 8px;
        }
    }
`

export const Options = styled.menu`
    background-color: #FFF;
    border-radius: 4px;
    padding: 16px;

    width: 100%;
    height: auto;

    & ul {
        list-style: none;
        padding: 0;
        margin: 0;

        & li {
            width: 100%;
            height: 50px;
            
            border-radius: 4px;

            padding: 16px;

            cursor: pointer;

            & + li {
                /* margin-top: 8px; */
            }

            &:hover {
                background-color: #eeeeee;
            }
        }
    }
`;